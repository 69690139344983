.chat-video {
  margin-top: 10px;
  background: linear-gradient(180deg, #ECECF7 0%, #F9F9FC 26.77%, #F9F9FC 63.02%, #F9F9FC 100%);
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 0px 0px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 42vh;
  overflow-x: hidden;
  z-index: 999;
  .connect-error {
    font-family: 'Rubik', sans-serif;
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #2F4895;

    max-width: 200px;
  }

  .try-button {
    border: 1px solid #2F4895;
    background-color: white;
    color: #2F4895;
    margin-top: 40px;
  }
  .try-button-text {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;

    text-align: center;
    color: #2F4895;
    border-radius: 10px;
  }
}

@screen lg {
  .chat {
    border-radius: 0px 110px 0px 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    overflow: hidden;
  }
}
