.auth {
  background-color: #FFFCFC;
  overflow-y: auto;
  overflow-x: hidden;

  .container--theia-mark {
    position: absolute;
    right: -87px;
    top: -223px;

    img {
      width: 82%;
    }
  }
  
  .checkbox > span.health::before {
    margin-bottom: 44px;
  }

  .checkbox > span.health::after {
    top: 8px;
  }
  
  .checkbox > span::before {
    float: left;
  }
  span.health {
    margin-top: 5px;
  }
}

.auth__alert-message {
  @apply text-center ml-8 mr-8 mb-5;

  color: #029976;
}

.auth-form {
  position: relative;
}

.auth__title {
  font-weight: 800;
  font-size: 32px;
  line-height: 50px;
}

.form-text{
  font-size: 14px;
  line-height: 1.6;
  color: #828282;
}

.form-text--has-company {
  font-size: 14px;
  line-height: 1.6;
  color: #828282;
  font-weight: 600;
}

.button-confirm {
  text-align: center;
  button {
    width: 100%;
    margin-top: 40px;
  }
}

.signup-button-confirm {
  text-align: center;
  button {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.forgot-password {
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
  font-weight: bold;
  opacity: 0.8;
}

.logo {
  margin-top: 15%;
}

.pre-login-text-explanation {
  padding-left: 40px;
  padding-right: 40px;
}

.buttons-content {
  display: flex;
  position: relative;
  margin-top: 10vh;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  .form-text{
    @apply text-darkGreyColor text-textSmall font-light;
  }
}
.signup {
  &::-webkit-scrollbar {
    display: block;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-top: 50px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #C7C7C7;
    border-radius: 10px;
  }
  .email-info-sign-up {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #BB3949;
    margin-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .signup-form {
    margin: 20px 0 20px;
    .toggle-label {
      color: #303030;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 19px;
      font-weight: 300;
    }
    .button-text-inline {
      display: contents;
    }
    button:focus {
      border: 1px solid #303030;
    }
    .help {
      color: #BB3949;
      font-size: 12px;
    }
  }
}

div.wrapper-pre-login {
  background-color: #E5495F;;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;

  div.curve.top {
    background-color: #F9F9F9;
    width: 100%;
    height: 40vh;
    border-bottom-left-radius: 100% 80%;
    border-bottom-right-radius: 50% 15%;
    z-index: 99;
    background-position-x: 59%;
    background-position-y: 0%;
    background-size: 140%;
  }

  div.curve.bottom {
    background: linear-gradient(156.04deg, #B14582 2.5%, #9A4178 97.5%);
    width: 100%;
    height: 76vh;
    opacity: 0.4;
    margin-top: 50px;
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
    position: absolute;
    top: 22%;
    z-index: 0;
    border-radius: 175%/200px 425px 0 0px;
  }
}

@screen md {
  .pre-login-text-explanation {
    padding-left: 0;
    padding-right: 0;
  }
  
  .buttons-content {
    padding-left: 0;
    padding-right: 0;
    margin-top: 40px;
  }
}

@screen lg {
  .auth {
    .container--theia-mark {
      position: absolute;
      right: -287px;
      top: -92px;
      img {
        width: 82%;
      }
    }

    .checkbox > span.health::before {
      margin-bottom: 15px;
    }
  }

  .forgot-password {
    right: 15%;
  }
  .main-bg {
    .btn {
      width: 58%;
    }
  }

  div.curve.bottom {
    border-radius: 100%/200px 325px 0 0px;
  }

  div.curve.top {
    background-position-x: 50%;
    background-position-y: 0%;
    background-size: 50%;
  }
  .signup {
    &::-webkit-scrollbar-track {
      margin-top: 100px;
    }
  }
  .buttons-content {
    margin-top: 40px;
  }
}

@media (min-height: 998px) {
  .buttons-content {
    margin-top: 30%;
  }
}