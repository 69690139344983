.message {
  @apply mt-1 ml-4;
}

.message__body {
  @apply py-3 px-4 ml-8;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.65);
  min-width: 80px;
  max-width: 90%;
}

.message--sender {
  @apply justify-end;

  .message__body {
    background-color: #E1E1F4;
    border-radius: 20px 20px 0 20px;
    @apply pl-6;
  }
}

.message--other {
  @apply pr-6;

  .message__body {
    background-color: #FFF;
    border-radius: 0 20px 20px 20px;
  }
}

.message--first-of-series {
  @apply mt-2;
}

.message--profile-picture {
  .message__body {
    @apply ml-0;
  }
}

.message__content {
  @apply text-base;
  display: flex;
  align-items: flex-end;
}

.message__text {
  word-break: break-word;
  height: auto;  
}

.message__text--collapsed {
  height: 315px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 13;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message__dots {
  display: block;
  height: 20px;
}

.message__timestamp {
  @apply text-right text-gray-600;
  font-size: 10px;
}

.message__specialist-name {
  font-size: 12px;
  font-weight: bold;
  color: #2F4895;
}
.chat-video {
  .message__specialist-name,
  .message__specialist-name--skeleton {
    height: 18px;
    text-transform: capitalize;
  }
}

.message__link {
  color: #2F4895;
  cursor: pointer;
}
