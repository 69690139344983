.booking-card-outer-container {
  #booking-details-modal {
    .right-drawer-inner-content {
      @apply overflow-auto;
    }
    .specialists-filter-modal {
      @apply pb-20 md:pb-4;
    }
  }
}

.booking-details-specialist-info {
  display: flex;
  flex-flow: column;
}

.booking-card-container {
  @apply bg-white;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 16px;
  margin-right: 16px;
  margin-left: 16px;
  border-radius: 16px;
  filter: drop-shadow(0px 4px 8px rgba(17, 17, 17, 0.04));
}

.booking-card-inner {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 16px;
}

.booking-card-left-container {
  @apply h-full;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  background: #F9FAFF;
  border-radius: 16px;
  padding: 8px 8px 8px 8px;
}

.booking-card-weekday {
  @apply text-fontExtraSmall text-textPrimary;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  padding: 8px 8px 3px 8px;
}

.booking-card-day {
  @apply text-fontSmall text-textPrimary;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  padding: 0 8px 13px 8px;
}

.booking-card-left-container-loading {
  padding: 0;
  margin-right: 16px;
}

.booking-card-picture {
  display: block;
  border-radius: 16px;
}

.booking-card-picture-loading {
  @apply bg-bgDisable;
  width: 72px;
  height: 72px;
  border-radius: 16px;
}

.booking-card-right-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.booking-card-right-container-header {
  display: flex;
  flex: 1;

  .tag-done {
    margin-right: 0;
  }
}

.booking-card-type {
  text-transform: capitalize;
  @apply text-fontDefault text-textPrimary;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  padding-bottom: 16px;
  display: flex;
  flex: 1;
}

.booking-card-type-loading {
  @apply bg-bgDisable;
  width: 74px;
  height: 16px;
  border-radius: 16px;
  margin-bottom: 24px;
}

.booking-card-name {
  @apply text-fontDefault text-textPrimary;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 3px;
}

.booking-card-name-loading {
  @apply bg-bgDisable;
  max-width: 190px;
  height: 16px;
  border-radius: 16px;
  margin-bottom: 8px;
}

.booking-card-category {
  @apply text-labelMedium text-textSecondary;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
}

.booking-card-category-loading {
  @apply bg-bgDisable;
  max-width: 190px;
  height: 16px;
  border-radius: 16px;
  margin-bottom: 23px;
}

.booking-card-hour-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.booking-card-icon-loading {
  @apply bg-bgDisable;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 16px;
}

.booking-card-hour {
  @apply text-fontDefault text-textPrimary;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  padding-left: 10px;
}

.booking-card-hour-loading {
  @apply bg-bgDisable;
  width: 74px;
  height: 16px;
  border-radius: 16px;
}

ul {
  .calendar-save-button {
    margin-top: 23px;
  }
  .list-item-caption {
    max-width: 60%;
  }
}

.booking-type-buttons-wrapper {
  @apply text-primary text-fontRegular;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  .btn-material {
    gap: 0 !important;
    width: auto;
    max-height: 35px;
    padding: 0;
    justify-content: left;

    .label-btn-material {
      margin-left: 0;
    }
  }

  .btn-material.small {
    gap: 0 !important;
    padding: 0;
  }
}

.booking-type-separator-button {
  @apply text-primary;
  padding-left: 20px;
  padding-right: 20px;
}

#booking-card-payment {
  padding-bottom: 16px;
}

.booking-details-footer {
  @apply bg-bgBox w-full fixed bottom-0 pb-14 md:pb-0;
}

.booking-details-button {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 16px 16px 16px 0;
}

.booking-details-card {
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 16px;
  margin: 2px 16px 10px 16px;
}

.booking-details-card-info {
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 18px;
  align-items: center;
}

.booking-details-card-info-container {
  padding-left: 18px;

  .booking-details-card-title {
    @apply text-textPrimary text-fontDefault;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
  }

  .booking-details-card-caption {
    @apply text-textSecondary text-fontExtraSmall;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
  }

  .risked-text {
    text-decoration-line: line-through;
  }
}

.footer-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;

  .btn-material {
    width: 50%;
  }

  .first-button {
    display: flex;
    flex: 1;
    border-right: 1px solid #EEEEEE;
    .btn-material {
      display: flex;
      flex: 1;
      width: 50%;
      &.text {
        &:hover:enabled {
          border-bottom-left-radius: 10px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:focus-visible {
          border-bottom-left-radius: 10px !important;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
        &:active:enabled {
          border-bottom-left-radius: 10px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  .second-button {
    display: flex;
    flex: 1;
    .btn-material {
      display: flex;
      flex: 1;
      width: 50%;
      &.text {
        &:hover:enabled {
          border-bottom-right-radius: 10px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 0;
        }
        &:focus-visible {
          border-bottom-right-radius: 10px !important;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
        &:active:enabled {
          border-bottom-right-radius: 10px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}

.know-more-button {
  display: flex;
  flex: 1;

  .btn-material {
    width: 100%;
    &.text {
      @apply text-textPrimary;
    }
  }
}

.booking-details-banner-state {
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 20px 16px 8px 16px;
  padding: 16px 20px 24px 20px;

  .booking-details-banner-state-title {
    @apply text-textPrimary text-fontDefault;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    text-align: center;
  }

  .booking-details-banner-state-description {
    @apply text-textSecondary text-fontExtraSmall;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    padding-top: 4px;
  }
}

.booking-details-banner-pendent-payment {
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 20px 16px 8px 16px;

  .booking-details-banner-pendent-payment-title {
    @apply text-textPrimary text-fontDefault;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    padding: 16px;
  }

  .booking-details-banner-pendent-payment-description {
    @apply text-textSecondary text-fontExtraSmall;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    padding: 4px 20px 16px 20px;
  }
}

.reschedule-title {
  @apply text-textPrimary text-fontDefault;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  padding: 20px 0 13px 21px;
}

@screen lg {
  .booking-card-container {
    margin-left: 0;
  }

  ul {
    .list-item-caption {
      max-width: 80%;
    }
  }

  .booking-details-button {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin: 16px 16px 16px 10px;
  
    .btn-material {
      .label-btn-material {
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        margin-left: 0;
      }
    }
  }
}

.booking-prescription-state {
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-left: 8px;
}

.booking-prescription-title {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 16px;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: normal;
}