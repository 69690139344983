.schedule-suggestion-banner-container {
  @apply bg-bgButton mb-4 md:mx-4 lg:mx-6 md:cursor-pointer md:rounded-2xl;
  &:focus-visible {
    outline: none;
    background-color: rgba(154, 65, 120, 0.11);
    border: 1px solid rgba(154, 65, 120, 0.11);
  }
  &:hover {
    background: rgba(154, 65, 120, 0.05);
  }
  &:active {
    background: rgba(154, 65, 120, 0.11);
  }
  .schedule-suggestion {
    .schedule-suggestion-img {
      width: 40px;
      height: 40px;
      & > svg {
        border-radius: 50%;
      }
      .schedule-suggestion-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(180deg, #B14582 0%, #9A4178 100%);
        right: -9px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        bottom: 0;
      }

      .prescription-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #723965;
        right: -9px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        bottom: 0;
      }
    }
    .anchor-container {
      width: fit-content;
    }
  }

  &.first-time {
    animation: slideDown 1.5s ease-in-out alternate forwards;
    -webkit-animation: slideDown 1.5s ease-in-out alternate forwards;
    -moz-animation: slideDown 1.5s ease-in-out alternate forwards;
    height: 0px;
    overflow: hidden;

    @keyframes slideDown {
      0%, 80% {
        height: 0;
        overflow: hidden;
      }
      100% {
        height: 76px;
        overflow: visible;
      }
    }
  }
}
.schedule-suggestion__loader-container {
  @apply md:px-4 lg:px-6 w-full mb-4;
  .schedule-suggestion__loader-card {
    @apply bg-bgButton md:rounded-2xl flex flex-row px-4 items-center;
    height: 76px;
    .schedule-suggestion__loader-img {
      @apply rounded-full bg-bgDisable h-10 w-10;
    }
    .schedule-suggestion__loader-text {
      @apply ml-6 mr-auto w-1/2 bg-bgDisable h-3 rounded-xl;
    }
    .schedule-suggestion__loader-btn-options {
      @apply w-6 mr-2 h-1 rounded-md bg-bgDisable;
    }
  }
}
