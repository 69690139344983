.all-posts__loader-container {
  @apply flex justify-center h-screen w-full bg-bgCanvas;
  .all-posts__loader {
    @apply flex items-center h-full;
  }
}
.all-posts__view {
  .all-posts__content {
    @apply overflow-y-auto p-4 mt-20 md:mt-0;
    max-height: calc(100vh - 55px - 86px);
    .all-posts__content-list {
      @apply mx-auto w-full lg:max-w-xl mb-8;
      .post-list {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
      
        button:focus {
          outline: none;
          box-shadow: 0 0 0 3px #000000;
          border-radius: 10px;
        }
      }
    }
  }
}

@screen md {
  .all-posts__view {
    .all-posts__content {
      overflow: unset;
      max-height: unset;
      .all-posts__content-list {
        @apply mb-0 pb-12;
      }
    }
  }
}
