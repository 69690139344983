.content-week-container {
  @apply p-4;
  .content-week-title {
    @apply text-fontDefault text-textPrimary;
    display: flex;
    text-align: left;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .content-week-empty {
    @apply text-fontDefault text-textSecondary;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    padding-left: 19px;
    text-align: center;
  }
  .carousel-content-container {
    margin-left: -16px;
    margin-right: -16px;
    .simple-carousel .simple-carousel-container {
      padding-left: 16px;
    }
  }
  .carousel-content-card {
    margin-right: 16px;
  }
}

.content-loader__skelleton-container {
  @apply w-full p-4;
  .content-loader__skelleton-title {
    @apply w-28 rounded-3xl h-6 mb-4;
  }
  .content-loader__skelleton-carousel {
    @apply w-full gap-4 flex overflow-hidden pl-4 relative;
    .content-loader__skelleton-gradient {
      @apply absolute w-full;
      height: 318px;
      width: 44px;
      z-index: 1;
      &.left {
        left: 0;
        background: linear-gradient(90deg, #F9FAFF 30%, rgba(249, 250, 255, 0) 100%);
      }
      &.right {
        right: 0;
        background: linear-gradient(90deg, rgba(249, 250, 255, 0) 30%, #F9FAFF 100%);
      }
    }
    .content-loader__carousel-item {
      @apply rounded-2xl;
      min-width: 298px;
      height: 318px;
    }
  }
}

@screen lg {
  .content-week-container {
    @apply p-0 pb-8;
    .carousel-content-container {
      .simple-carousel .simple-carousel-container {
        padding-left: 32px;
      }
    }
  }
}