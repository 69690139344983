.carousel-weeks {
  padding-left: 8px;
}

.week-item-default {
  height: 128px;
  border-radius: 16px;
  margin-right: 6px;
  text-align: left;
  border-color: transparent;
  border-width: 1px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: left;
}

.current-week-container {
  display: flex;
  flex: 1;
  width: 100%;
  
  .current-week-text {
    @apply bg-primaryDark text-bgBox text-fontExtraSmall;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 0px 16px;
    padding: 0 6px;
  }
}

.week-item-grey {
  background: #F0F1FB;

  &:focus:enabled {
    border: none;
  }
  &:focus-visible {
    border: 2px solid #723965 !important;
  }
}

.week-item-purple {
  @apply bg-primary;

  &:focus:enabled {
    border: none;
  }
  &:focus-visible {
    background-color: #9A4178;
    border: 2px solid #723965 !important;
  }
}

.expanded-week-item {
  width: 226px;
}

.normal-week-item {
  width: 64px;
  text-align: center;
}

.current-week-caption {
  @apply text-fontExtraSmall text-white;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex: 1;
  padding: 16px 0 0 16px;
}

.week-caption {
  @apply text-fontExtraSmall;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
}

.week-name-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;

  &.week-name-container-current {
    padding: 0 16px 16px 16px;
  }

  &.week-name-container-not-current {
    padding: 16px;
  }
}

.week-name-text-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin-right: 8px;
}

.week-name-baby-size-text {
  @apply text-fontExtraSmall;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  padding-top: 6px;
}

.week-name {
  @apply text-fontDefaultSecondary;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  margin-top: 2px;
}

.week-name-fruit-picture-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .week-name-size-picture {
    width: 48px;
    height: 48px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.2);
  }
}


.current-week-chip-text {
  @apply text-fontExtraSmall;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  margin-top: 5px;
  height: 24px;
  text-align: center;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.current-week-chip-text-selected {
  @apply bg-primary text-white;
}

.current-week-chip-text-not-selected {
  @apply bg-overlayLight text-textPrimary;
}

.week-carousel__skelleton-container {
  @apply w-full my-4;
  max-width: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .carousel__skelleton {
    @apply flex px-4 gap-2;
    min-width: fit-content;
      .larger-carousel-item__skelleton {
        @apply rounded-2xl w-56 h-32;
      }
      .carousel-item__skelleton {
        @apply rounded-2xl w-16 h-32;
      }
  }
}

@screen lg {
  .week-item-grey { 
    &:hover:enabled {
      background: rgba(154, 65, 120, 0.05);
      color: #000000;
    }
  }
  
  .week-item-purple {
    &:hover:enabled {
      @apply bg-primaryLight;
      .current-week-chip-text {
        @apply bg-overlayLight;
      }
    }
  }
}
