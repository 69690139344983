.btn-material.text.btn-delete-plan {
  color: #BB3949;

  &:hover:enabled {
    color: #BB3949;
  }

  &:focus-visible {
    color: #BB3949 !important;
  }
}

.modal-container.centered.delete-plan-modal {
  padding: 0px;
  max-width: 400px;
  min-width: 250px;
}

.health-plan-welcome-card svg {
  width: 80px;
  height: 80px;
}

.item-b.app {
  height: 100%;

  .checking-health-insurance {
    height: calc(100% - 230px);
  }
}

.back-button-svg {
  width: 14px;
  height: 14px;
}

.new-anchor-group {
  .options-modal-inner {
    .list-item-container {
      padding: 0;
    }
    .body-container {
      margin: 12px 0px;
      padding: 0;
    }
  }
}

.profile-health-card {
  margin: 1rem;
  .anchor-container {
    width: 100%;
  }
  .body-container {
    padding: 0px;
  }
  .list-item-container {
    padding: 20px 0px;
    svg {
      margin: 0 16px;
      width: 40px;
      height: 40px;
    }  
  }
}

@screen md {
  .sticky-profile-bar {
    width: calc(100% - 12px);
  }
}

@screen lg {
  .profile-health-card {
    margin: 1rem 0;

    .anchor-container {
      .options-modal {
        width: 100%;
      }
    }
  } 
}