.text-explanation {
  @apply text-darkGreyColor;
  padding-left: 40px;
  padding-right: 40px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
}

.know-theia-container {
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.button-container {
  position: relative;
  margin-top: 30px;
  margin-bottom: 32px;
  left: 0;
}

.icon-welcome {
  position: relative;
  bottom: 10%;
  right: 0;
}

@screen md {
  .know-theia-container {
    padding-left: 0;
    padding-right: 0;
  }

  .text-explanation {
    padding-left: 0;
    padding-right: 0;
  }
  .screen-title-container.sales-welcome {
    padding-top: 40px !important;
  }
}

@screen lg {
  .button-container {
    margin-bottom: 50px;
  }
  .screen-title-container.sales-welcome {
    padding-top: 0px;
  }
}