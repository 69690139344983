.profile-view {
  @apply w-full pb-14;
  max-height: calc(100vh - 55px);
  .profile-view-grid-container {
    @apply overflow-y-auto;
    max-height: calc(100vh - 76px - 55px);
    margin-top: 76px;
    padding-top: 24px;
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    grid-column-gap: 16px;
    grid-template-rows: auto;
  
    .profile-view-item-a {
      @apply bg-bgCanvas;
      grid-column-start: 1;
      grid-column-end: 5;
      display: block;
    }
    .profile-view-item-b {
      display: none;
    }
    .profile-card-container-item-a {
      padding: 0 16px 0 16px;
  
      .new-list-loading-container {
        padding-left: 0;
      }
    }
  
    .list-item-body-container {
      .body-container {
        .title-container {
          .list-item-title {
            display: block; /* Fallback for non-webkit */
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  
    .profile-card {
      margin: 16px 16px 0 16px;
      height: auto;
      .profile-icon {
        margin: 0 16px;
        svg {
          border-radius: 16px;
        }
      }
    }
  
    .list-item-caption {
      max-width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .drawer-material {
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: block;
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      margin-top: 10px;
      margin-bottom: 80px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #C7C7C7;
      border-radius: 10px;
    }
  }
  .options-modal {
    left: 0;
    width: 100%;
  }
  .profile-card-container-item-a {
    .anchor-container {
      width: 100%;
    }
  }
  
  .alert-profile-picture {
    width: 340px;
    .icon-LoadingLight {
      animation: spin 2s infinite linear
    }
  }
  
  .profile-view-drawer-form {
    .profile-view-drawer-inner-form {
      padding: 0 16px 16px 16px;
      margin-bottom: 80px;
    }
    .profile-view-drawer-moment-inner-form {
      padding: 0 0 16px 16px;
      margin-bottom: 80px;
  
      .label-medium-radio-group-list {
        margin-bottom: 16px;
      }
  
      .extra-inputs-container {
        margin-right: 16px;
      }
    }
    .profile-view-individual-container {
      margin-top: 16px;
    }
    .shared-container {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
  
      .second-container {
        margin-top: 16px;
      }
    }
  
    .profile-view-drawer-footer {
      background: white;
      position: fixed;
      bottom: 0;
      width: 100%;
    }
    .profile-view-drawer-button {
      display: flex;
      flex: 1;
      flex-direction: row;
      margin: 16px 16px 16px 0;
    }
  }
  
  .profile-contact-message{
    font-size: 13px;
    text-align: center;
    margin: 30px 0 60px 0;
  
    a {
      color: #E5495F;
    }
  }
  
  .button-bottom {
    border-right: 1px solid #E2E8F0;
  }
  
  .logout {
    @apply flex mt-4;
  
    p.label-btn-material.text {
      padding-left: 16px;
    }
  
    .logout__button {
      background: #FFFFFF;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      padding: 7px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    }
  
    .logout-failed-message {
      color: #E5495F;
    }
  }
  
  .editing-cards {
    display: flex;
    flex-flow: column;
  }
  
  .profile-card-item {
    flex-basis: 0;
  }
}

@screen md {
  .profile-view {
    max-height: 100vh;
    .profile-view-grid-container {
      max-height: 100vh;
      margin-top: 0;
    }
  }
  .profile-view-drawer-form {
    .profile-view-drawer-footer {
      width: 30%;
    }
  }
}

@screen lg {
  .profile-view {
    .profile-view-grid-container {
      grid-template-columns: repeat(12, [col-start] 1fr);
      grid-column-gap: 24px;
      border-collapse: separate;
      
      .profile-view-item-a {
        @apply bg-bgCanvas;
        grid-column-start: 1;
        grid-column-end: 8;
        display: block;
        padding: 0 0 0 16px;
      }
      
      .profile-view-item-b {
        @apply bg-bgCanvas;
        grid-column-start: 8;
        grid-column-end: 13;
        display: block;
        padding: 0 16px 0 0;
      }
  
      .profile-card-container-item-a {
        display: none;
      }
  
      .divider-item-a {
        display: none;
      }
      .profile-card {
        margin: 16px 0 0 0;
      }
      .logout {
        .btn-material.logout-btn {
          padding-left: 0;
        }
      }
      .editing-cards {
        display: flex;
        flex-flow: row;
      }
    
      .first-card-item {
        margin-right: 0.75rem;
      }
    }
  }

  .profile-view-drawer-form {
    .shared-container {
      display: flex;
      flex-direction: row;
      margin-top: 16px;

      .first-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-right: 8px;
      }
  
      .second-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-top: 0;
        margin-left: 8px;
      }
    }
    .profile-view-drawer-button {
      display: flex;
      flex: 1;
      flex-direction: row;
      margin: 16px 16px 16px 10px;
    
      .btn-material {
        margin-right: 10px;
        .label-btn-material {
          font-size: 14px;
          line-height: 14px;
          font-weight: 500;
          margin-left: 0;
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}