.button-placeholder {
  @apply bg-bgDisable;
  border-radius: 8px;
  height: 48px;
}

@screen lg {
  .button-placeholder {
    max-width: 343px;
  }
}