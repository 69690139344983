.signup { 
  padding-bottom: 40px;
}

.semi-logo-theia {
  position: fixed;
  bottom: 0;
  left: 0;
}

button.btn-dateInput-position {
  margin-top: 280px;
}

.sales-onboarding-container {
  height: calc(var(--vh, 1vh) * 100);
  padding: 0 40px;
  .pediatric-onboarding-text {
    @apply mt-2 mb-6 text-bodyLarge text-textPrimary text-justify;
  }
  .bottom-button-date-container {
    display: flex;
    flex: 1;
    max-width: 600px;
    align-items: center;
    justify-content: center;
  }

  .sales-onboarding-title {
    padding-right: 55px;
  }

  .sales-onboarding-subtitle {
    @apply text-darkGreyColor;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-top: 34px;
    padding-bottom: 47px;
  }

  .sales-onboarding-specialist-card {
    background: #FFFFFF;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }

  .sales-onboarding-schedule-message {
    @apply mt-2;
    background: #FFFFFF;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;

    .schedule-message__header {
      align-items: center;
    }

    .payment-value {
      font-family: 'Rubik', sans-serif;
      text-align: left;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      margin-top: 12px;
    }

    .schedule-message__professional-name {
      font-weight: 500;
      padding-top: 8px;
      padding-bottom: 8px;
      color: #303030;
      font-size: 14px;
      line-height: 17px;
    }

    .normal-text {
      @apply text-darkGreyColor;
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
    }

    .schedule-message__professional-local {
      font-size: 12px;
      line-height: 14px;
      color: #303030;
      flex: 1;
    }

    .schedule-message__professional-category {
      font-size: 12px;
      line-height: 14px;
      color: #585858;
      flex: 1;
    }
  }

  .stepper-section {
    width: 100%;
    margin: 0px auto;
  }

  .text-content {
    @apply text-darkGreyColor;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
  }

  .text-payment-content {
    @apply text-primaryDark;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }

  .text-doubts-content {
    @apply text-darkGreyColor;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  }

  .text-content-plan {
    @apply text-darkGreyColor;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 300;
    text-align: left;
  }

  .text-success-content {
    @apply text-textPrimary;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
  }

  .doubts-text-button {
    width: 100%;
  }

  .doubts-title {
    text-align: left;
    padding-right: 40px;
    display: flex;
    flex: 1;
  }

  .card-button {
    width: 100%;
  }

  .pregnant-card {
    background-color: #CBB6FF;
  }
  .text-pregnant-card {
    display: flex;
    flex: 1;
    padding-right: 10px;
  }

  

  .journey-content {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .negative-live-in-sp {
    border: 1px solid #723965;
    border-radius: 12px;
    margin-left: 20px;
    color: #723965;
  }

  .specialist-card {
    background-color: #ABF5FF;
  }

  .obstetrician-card {
    background-color: #FFC4CD;
  }

  .icon-professional-card {
    padding-top: 20px;
    padding-right: 16px;
  }

  .card-content {
    position: relative;

    .card-content-inner {
      max-width: 70%;
      align-items: flex-start;
    }
  }

  .card-icon {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .basic-card {
    background-color: #CBB6FF;
  }

  .text-explanation-with-width {
    @apply text-darkGreyColor;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    max-width: 91%;
  }
  .wapp-button-img {
    position: absolute;
    right: 40px;
    bottom: 80px;
  }
}

.sales-onboarding-blocked-container {
  @apply justify-between;
  margin-top: 0;
  padding: 0 40px 40px;
  height: calc(100vh - 40px);
}

.schedule-payment {
  padding: 0;

  &::-webkit-scrollbar {
    display: block;
    width: 5px;
    margin-left: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 10px;
    border: 1px solid rgba(160, 112, 112, 0.2);
    box-sizing: border-box;
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #C7C7C7;
    border-radius: 10px;
  }

  .stripe-payment {
    padding: 0 !important;
  }
}

.field-explanation {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #303030;
  padding-bottom: 8px;
}

@screen md {
  .semi-logo-theia {
    display: none;
  }

  .sales-onboarding-container {
    padding: 0;
    .text-explanation-with-width {
      max-width: 72%;
    }
    .text-pregnant-card {
      display: flex;
      flex: 1;
      padding-right: 0;
    }
    button.btn-sales {
      position: relative;
      margin-left: 0;
      margin-right: 0;
      margin-top: 100px;
      width: 100%;
    }
  }
  button.btn-dateInput-position {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    margin-top: 100px;
    width: 100%;
  }
}

@screen md {
  .sales-onboarding-blocked-container {
    @apply justify-start;
    margin-top: -100px;
    padding: 0;
    height: auto;
  }
}

@screen lg {
  .sales-onboarding-container {
    padding: 40px 0 0 0;
    .text-explanation-with-width {
      max-width: 72%;
    }
  }

  .form-status-container {
    margin-top: - 100px;
  }
}

.terms-checkbox {
  &.newest-checkbox-container {
    flex: none;
  }
}