.form-cep-input-container {
  .cep-container{
    display: flex;
    flex-direction: column;
    align-items: left;

    .cep-input-container{
      display: flex;
      flex: 1;
      max-width: 329px;
      position: relative;

      .input-group {
        flex: 1;
      }
    }
    .input-icon {
      position: absolute;
      margin-top: 8px;
      top: 37px;
      right: 12px;
    }

    .btn-material {
      padding: 5px 5px 5px 0;
      margin-top: 10px;
      margin-left: 0;

      &.small-btn-material {
        height: 16px;
      }
    }
    .label-btn-material {
      @apply text-fontExtraSmall;
      text-align: left;
      margin-left: 0;
    }
  }

  .city-state-container {
    display: flex;
    flex-direction: row;
    padding-top: 16px;

    .city-container {
      max-width: 211px;
      margin-right: 24px;
    }

    .state-container {
      max-width: 94px;
    }
  }

  .number-complement-container {
    display: flex;
    flex-direction: row;
    padding-top: 16px;

    .number-container {
      max-width: 96px;
      margin-right: 22px;;
    }

    .complement-container {
      flex: 1;
    }
  }

  .name-container {
    margin-top: 16px;
  }
}

@screen lg {
  .form-cep-input-container {
    .cep-container{
      display: flex;
      flex-direction: row;
      align-items: center;

      .btn-material {
        padding: 5px;
        margin-top: 32px;
        margin-left: 24px;
      }
    }
  }
}