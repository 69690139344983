.save-dependent-data-view {
  @apply overflow-y-auto bg-bgBox relative;
  height: calc(100vh - 55px);
  .save-dependent-form {
    @apply flex flex-col justify-between flex-1 max-w-xl mx-auto p-4;
    height: calc(100% - 76px);
    .save-dependent-form-content {
      .chips-container {
        @apply flex items-start gap-4 mt-4;
      }
    }
    .save-dependent-form-btn {
      @apply mt-4 pb-4;
    }
  }
  .save-dependent-snackbar-error {
    @apply absolute bottom-4 md:bottom-6 px-4 mx-auto w-full;
    .snackbar-container.alert {
      @apply max-w-xl;
      margin: 0 auto;
      background-color: rgba(0,0,0,0.85);
    }
  }
  .save-dependent-data-loader {
    @apply w-full flex h-1/2 justify-center items-center;
  }
}

@screen md {
  .save-dependent-data-view {
    height: calc(100vh);
  }
}