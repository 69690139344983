.new-schedule-flow-container {
  @apply h-screen max-h-screen bg-bgCanvas overflow-y-auto;
  .new-schedule-flow-header {
    @apply grid border-separate gap-x-4 grid-cols-12 sticky top-0 h-20
    w-full bg-bgCanvas z-10;
    grid-template-rows: auto;
    .new-schedule-flow-header-content {
      @apply flex flex-1 items-center p-4 col-start-1 col-end-13;
      .header-text {
        @apply flex flex-1 flex-col items-start;
        h1 {
          @apply text-bodyLarge md:text-headlineSmall text-textPrimary;
        }
        p {
          @apply text-bodyMedium md:text-bodyLarge text-textSecondary;
        }
      }
      .logo-theia {
        @apply flex flex-1 flex-grow max-h-10;
      }
    }
  }
  .new-schedule-flow-body {
    @apply grid border-separate gap-x-4 grid-cols-12;
    height: calc(100vh - 5rem);
    grid-template-rows: auto;
    .new-Schedule-flow-body-content {
      @apply col-start-1 col-end-13 block p-4;
    }
  }
  .schedule-flow-loader {
    @apply flex flex-col items-center justify-center h-1/3;
  }
}

@screen md {
  .new-schedule-flow-container {
    .new-schedule-flow-header {
      @apply h-24;
      .centered-head {
        @apply col-start-4 col-end-10 py-4 px-0;
      }
    }
    .new-schedule-flow-body {
      @apply gap-x-6;
      height: calc(100vh - 6rem);
      .new-Schedule-flow-body-content {
        @apply px-0 pb-0 pt-12;
      }
      .with-payment-resume {
        @apply col-start-2 col-end-8;
      }
      .without-payment-resume {
        @apply col-start-4 col-end-10;
      }
    }
  }
}

@screen lg {
  .new-schedule-flow-container {
    .new-schedule-flow-body {
      .with-payment-resume {
        @apply col-start-2 col-end-7;
      }
    }
  }
}