.reschedule-details__container {
  @apply not-italic;
  font-family: 'Rubik', sans-serif;
  .reschedule-details__title {
    @apply text-primaryDark pt-6 font-medium text-center items-center;
    font-size: 20px;
    line-height: 24px;
  }
  .reschedule-details__label-info {
    @apply text-greyColor text-center pb-2 pt-5 font-normal;
    font-size: 12px;
    line-height: 14px;
  }
  .reschedule-details__text-info {
    @apply text-darkGreyColor font-medium text-center;
    font-size: 14px;
    line-height: 17px;
  }
  .reschedule-details__address-text-info {
    @apply text-darkGreyColor text-center font-light self-center pt-1;
    font-size: 14px;
    line-height: 17px;
  }
}