.schedule-view-container {
  @apply flex flex-col w-full h-screen pb-16;
  .schedule-view-body {
    @apply px-4 grid grid-cols-12 py-2 flex-1 overflow-auto mt-20;
    grid-template-rows: auto;
    .schedule-view-content {
      @apply col-start-1 col-end-13;
    }
  }
  .schedule-view-loader {
    @apply flex flex-col items-center justify-center h-1/3;
  }
}

@screen md {
  .schedule-view-container {
    @apply overflow-auto pb-0;
    .schedule-view-body {
      @apply mt-0;
      overflow: unset;
      .schedule-view-content {
        @apply col-start-4 col-end-10;
      }
    }
  }
}