.menu-item {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &.sidebar {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    padding: 16px;
    .nav-item-text {
      @apply text-left;
      padding-left: 14px;
    }
    &:hover:enabled {
      @apply text-primaryLight bg-bgButton;
      border-radius: 50px;
    }
    &:focus:enabled {
      @apply bg-overlayButton text-primary;
      border-radius: 50px;
    }
    &:active:enabled {
      @apply text-primary;
    }
  }

  &.bottombar {
    font-size: 14px;
    line-height: 16px;
    flex-flow: column;
    flex-grow: 1;
    justify-content: center;
    height: 55px;
    padding: 0 auto;
    i {
      margin-bottom: 5px;
      font-size: 18px;
    }
    &:focus:enabled {
      @apply text-primaryLight;
      border: 1px solid #B14582;
    }
    &:active:enabled {
      @apply text-primary;
    }
  }

  &.tab {
    @apply relative flex flex-col justify-center flex-grow text-fontExtraSmall;
    height: 55px;
    padding: 0 10px;
    overflow: visible;
    .nav-item-text {
      @apply text-bodyLarge;
    }
    i {
      margin-bottom: 5px;
      font-size: 18px;
    }
    &:focus-visible:enabled {
      @apply text-primaryLight;
      border: 1px solid #B14582;
    }
    &:active:enabled {
      @apply text-primary;
    }
    &.nav-item-active {
      border-bottom: 3px solid #9A4178;
    }
  }

  .label-btn-material {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    margin-left: 10px;
  }

  .nav-item-tooltip-message {
    position: absolute;
    top: 100%;
    width: 100%;
    max-width: 200px;
    margin-left: -5px;
    .indicator {
      height: 22px;
      &.right-top {
        top: -17px;
      }
    }
  }
}

@media (min-width: 374px) {
  .menu-item {
    .nav-item-tooltip-message {
      top: 80%;
    }
  }
}