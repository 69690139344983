.animation-up {
  -webkit-animation: animation-check 1.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: animation-check 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 2.5s;
  position: absolute;
  top: 39%;
}

.show-content {
  animation: animation-content 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 2.7s;
  max-width: 600px;
  position: absolute;
  top: 39%;
  margin-top: 170px;
}

.button-faq-description {
  margin-left: -16px;
}

.button-healthplan-feedback {
  width: 100%;
}

.list-item {
  li {
    padding: 10px 0px;
  }
}

@keyframes animation-content {
  0% {
    opacity: 0;
    top: 39%;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
@keyframes animation-check {
  0% {
    top: 39%;
    transform: scale(1);
  }
  100% {
    top: 0;
    transform: scale(0.65);
  }
}

@screen md {
  .accordion-wrapper {
    max-width: 600px;
    min-width: 600px;
  }
  
  .button-healthplan-feedback {
    width: 343px;
  }
}


.accessible-modal {
  .accordion-wrapper {
    max-width: 100%;
    min-width: 100%;
  }

  .animation-up {
    -webkit-animation: animation-check-modal 1.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: animation-check-modal 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-delay: 2.5s;
    position: inherit;
    margin: 20%;
  }
  
  .show-content {
    animation: animation-content-modal 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-delay: 2.7s;
    max-width: 600px;
    position: inherit;
    margin-top: -20px;
  }
  
  .button-faq-description {
    margin-left: -16px;
  }
  
  .button-healthplan-feedback {
    width: 100%;
  }

  @keyframes animation-content-modal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes animation-check-modal {
    0% {
      margin-top: 20%;
      transform: scale(1);
    }
    100% {
      margin-top: -60px;
      transform: scale(0.65);
    }
  }
}