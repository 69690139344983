.consultation-details-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFF;
  overflow: hidden;

  .consultation-details-header {
    display: none;
  }

  .consultation-details-body {
    .consultation-details-image {
      object-fit: cover;
      height: 281px;
      max-height: 30vh;
      width: 100%;
      .consultation-image-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 16px;
        .checkbox-container {
          display: flex;
          align-items: center;
          align-content: center;
          gap: 8px;
          .checkbox-style {
            margin: 0 !important;
            padding: 0 !important;
            background-color: #F0F1FB;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            > label {
              margin: 0 !important;
              > span {
                margin: 0 !important;
              }
            }
          }
        }
      }
    }

    .consultation-details-info {
      max-height: 80vh;
      position: relative;
      background-color: rgb(255, 255, 255);
      border-radius: 16px 16px 0 0;
      margin-top: -45px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .info-text-container {
        height: 100%;
        overflow-y: auto;
        margin-bottom: 180px;
        &::-webkit-scrollbar {
          display: none;
        }
        .tag-done {
          margin: 0;
        }
      }
      .info-button-container {
        position: fixed;
        width: 100%;
        bottom: 55px;
        padding: 16px;
        background-color: #FFF;
      }
    }  
  }
}

@media (min-width: 768px) {
  .consultation-details-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    .consultation-details-header {
      @apply text-textPrimary text-xl font-normal;
      display: flex;
      padding: 1rem;
      align-items: center;
      justify-content: flex-start;
    }
    .consultation-details-body {
      padding: 20px 12%;
      height: 100%;
      max-height: 95vh;
      background-color: #F9FAFF;
      .consultation-details-image {
        border-radius: 16px 16px 0 0;
        .consultation-image-header {
          display: none;
        }
      }
    
      .consultation-details-info {
        border-radius: 16px;
        overflow: hidden;
        height: 72%;
        max-height: 64vh;
        box-shadow: 0px 2px 4px rgba(17, 17, 17, 0.04);
        .info-text-container {
          margin-bottom: 0;
        }
        .info-button-container {
          position: relative;
          width: 100%;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .consultation-details-container {
    position: relative;
    width: 100%;
  
    .consultation-details-header {
      display: none;
    }
    .consultation-details-body {
      padding: 16px 12px;
    }
  }
}