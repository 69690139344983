.stepper-section {
  .stepper-title {
    text-align: center;
    text-transform: uppercase;
  }
  .stepper-bar {
    position: relative;
    display: flex;
    .loading-stepper-line {
      @apply bg-bgDisable;
      width: 100%;
      display: block;
      position: absolute;
      top: 3px;
    }
    .stepper-line {
      width: 100%;
      color: transparent;
      display: block;
      position: absolute;
      top: 3px;
    }
    .stepper-progress {
      display: block;
      animation: expandWidth 2s linear;
      &.not-animated {
        animation: none;
      }
    }
  }
  .stepper-infos-container {
    display: flex;
    position: relative;
    margin-top: 18px;
    flex-direction: row;
    align-items: center;
  }
  .stepper-progress-info {
    @apply text-textSecondary text-fontExtraSmall;
    display: flex;
    text-align: right;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
  }
  .stepper-explanation-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
  }
  .step-text {
    display: none;
  }
  .stepper-explanation-text {
    @apply text-textPrimary text-fontDefault;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    margin-left: 4px;
  }
  .loading-explanation-container {
    display: flex;
    flex: 1;
    .loading-explanation {
      @apply bg-bgDisable;
      border-radius: 8px;
      height: 16px;
      width: 189px;
    }
  }
  .loading-step {
    @apply bg-bgDisable;
    border-radius: 8px;
    height: 14px;
    width: 75px;
  }
}

@screen md {
  .stepper-section {
    .step-text {
      display: block;
    }
  }
}

@keyframes expandWidth {
  0% { width: 0; }
  100% { width: 100%; }
}
