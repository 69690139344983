.participant__remote-video {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  transform: scale(-1, 1);
}

.participant__local-video {
  position: absolute;
  z-index: 3;
  bottom: 110px;
  right: 16px;
  width: 92px;
  height: 120px;
  border-radius: 10px;
  object-fit: cover;
  transform: scale(-1, 1);
}

.participant__local-video--disabled {
  z-index: 4;
  background-color: #979797;
  @apply flex;
  align-items: center;
  justify-content: center;
}

@screen lg {
  .participant__local-video {
    bottom: 35px;
    right: 35px;
    width: 180px;
    height: 120px;
    border-radius: 10px;
    object-fit: cover;
  }

  .participant__remote-video--vertical {
    object-fit: contain;
  }
}
