.bar-with-modal-overlay {
  background: rgba(0, 0, 0, .2);
  z-index: 2;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}

.bar-with-modal-container {
  @apply bg-bgBox w-full;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  z-index: 5;
  transition: height 0.6s ease;

  .bar-with-modal-mobile-header {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;

    .bar-with-modal-mobile-title {
      @apply text-fontDefault text-textPrimary;
      display: flex;
      flex: 1;
      justify-content: flex-start;
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: 500;
      align-items: center;
      padding: 16px 10px 16px 16px;
    }

    .bar-with-modal-mobile-subtitle {
      @apply text-fontDefault text-textSecondary;
      display: flex;
      justify-content: flex-end;
      padding: 16px 0 16px 0;
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: normal;
      align-items: center;
    }
  }
  .mobile-open-container {
    padding: 0 16px;
  }
}

@screen md {
  .bar-with-modal-container {
    display: none;
  }
}