.sidebar-content {
  @apply flex-col mb-0;
  position: relative;
  display: none;
  ul {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin-left: 9px;
  }
  .menu__logo {
    @apply block mx-auto;
    padding-bottom: 16px;
    margin-left: 25px;
    .logo {
      width: 116px;
    }
  }
  .pregnancy-card-popup__loading {
    @apply px-4 z-20 min-w-[300px];
  }
  .pregnancy-card-popup {
    @apply absolute z-20;
    width: 100%;
    bottom: 60px;
    right: 16px;
    .alert-message-container {
      @apply px-4;
    }
  }
  .alert-container {
    @apply hidden md:block;
    &.upload-exams-popup {
      position: absolute;
      z-index: 3;
      bottom: 50px;
      min-width: 200px;
    }
  }
}

.bottombar-content {
  @apply mb-0 bg-bgBar;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  height: 55px;
  display: block;
  ul {
    display: flex;
    li {
      flex: 1 1 0%;
      justify-content: center;
      width: 100%;
    }
    .drawer-material {
      margin-bottom: 0;
      border-radius: 16px 16px 0px 0px;

      &.bottomDrawer {
        height: auto;
      }
    }
  }
  .pregnancy-card-popup__loading {
    @apply px-4 z-20 w-full mx-4;
  }
  .pregnancy-card-popup {
    @apply absolute z-20;
    width: 200px;
    bottom: 0;
    right: 16px;
    .alert-message-container {
      @apply px-4;
    }
  }
  .alert-container {
    @apply block md:hidden;
    &.upload-exams-popup {
      position: absolute;
      z-index: 3;
      bottom: 58px;
      right: 5%;
      margin-left: 5%;
      min-width: 200px;
    }
  }
}

.bottom-bar-more-modal {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  .divider-top {
    background: #BCBEC5;
    border-radius: 100px;
    width: 40px;
    height: 4px;
    align-self: center;
    margin-bottom: 12px;
  }

  ul {
    display: flex;
    flex: 1;

    li {
      display: flex;
      flex: 1;
    }
  }
}

@screen md {
  .sidebar-content {
    display: block;
  }

  .bottombar-content {
    display: none;
  }
}
