.accordion__section {
  display: flex;
  flex-direction: column;
}

.accordion {
  color: #444;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  border-bottom: 1px solid #C7C7C7;
}
button:focus {
  outline: 0;
}

.accordion__title {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

.rotate {
  transform: rotate(90deg);
}

.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
  padding: 0 20px;
}
