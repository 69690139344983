.guided-journey__loader-container {
  @apply p-4;
  .loader__title-skelleton {
    @apply rounded-3xl h-6 w-3/4 md:w-2/3 my-4;
  }
  .loader__quarter-skelleton {
    @apply py-4 flex w-full justify-between items-center;
    .quarter-image-skelleton {
      @apply h-10 w-10 rounded-full;
    }
    .quarter-text {
      @apply flex flex-col items-start flex-1 px-4;
      .quarter-title-skelleton {
        @apply h-5 w-full md:w-3/4 rounded-3xl;
      }
      .quarter-week-skelleton {
        @apply h-4 w-full md:w-3/4 rounded-3xl mt-1;
      }
    }
    .tooltip-skelleton {
      @apply h-6 w-16 rounded-3xl mx-4;
    }
    .icon-skelleton {
      @apply h-5 w-5 rounded-full;
    }
  }
}
.guided-journey {
  @apply w-full flex flex-col mb-8;
  .journey-title {
    @apply text-base font-medium text-textPrimary mb-4 ml-4 md:ml-6 lg:ml-0;
  }
}