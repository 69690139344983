.couponForm {
  flex-direction: row;
  flex: 1;
  width: 100%;
  display: flex;
  input {
    @apply text-textSecondary text-fontDefault;
    font-family: 'Rubik', sans-serif;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    font-weight: 600;
    padding: 10px;
    flex: 1;
    width: 100%;
  }
  ::placeholder {
    @apply text-textSecondary text-fontDefault;
    font-weight: normal;
    align-items: center;
  }
  input, input:focus, input:active {
    @apply text-textSecondary text-fontDefault;
    opacity: 0.8;
    &.coupon-input {
      margin-right: 10px;
    }
  }
  input:-internal-autofill-selected {
    font-family: 'Rubik', sans-serif;
    color: #717172 !important;
  }
}

.couponPromotionalCodeError {
  @apply text-error;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  width: 100%;
  padding-top: 12px;
  font-family: 'Rubik', sans-serif;
  margin-left: auto;
}