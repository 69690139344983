.modal {
  @apply w-full flex items-center justify-center h-screen p-4;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 10001;
  opacity: 1;
  background: rgba(0, 0, 0, .48);
  .feedback__button {
    @apply text-center border-secondary text-secondary border pl-4 pr-4 m-1;
    font-size: 12px;
    border-radius: 20px;
    line-height: 14px;
    height: 30px;
    width: 100px;
  }
  &.full-mobile {
    @apply p-0;
  }
}

.modal-overlay {
  @apply flex items-center justify-center p-4;
  background: rgba(0, 0, 0, .8);
  z-index: 2;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}

.accessible-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 9999;
  opacity: 1;
  padding: 0;
  .feedback__button {
    @apply text-center border-secondary text-secondary border pl-4 pr-4 m-1;
    font-size: 12px;
    border-radius: 20px;
    line-height: 14px;
    height: 30px;
    width: 100px;
  }

  .modal__inner {
    .placeholder-span {
      width: 32px;
    }
  }
}

.modal__inner {
  @apply bg-white shadow p-6 max-h-full max-w-xs;
  border-radius: 20px;
}

.modal__inner::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.modal__inner::-webkit-scrollbar-thumb {
  background-color: #A6A4A4;
}

.modal__inner.health-plan {
  overflow: overlay;
}

.modal__title {
  @apply font-bold pl-4 pr-4 pb-2 pt-2;
  line-height: 26px !important;
  font-size: 22px;
}

.modal__info {
  @apply text-info;
}

.modal__inner--full-mobile {
  @apply bg-white shadow flex flex-1 mt-auto w-full rounded-t-2xl
  md:mt-0 md:rounded-2xl md:max-w-lg md:h-fit;
  max-height: calc(100vh - 4.5rem);
}

@screen sm {
  .accessible-modal {
    padding: 1rem;
    .modal__inner {
      .close-button-container {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
      .close-back-button-container {
        flex: 1;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
      }
    }
  }
}