.prescriptions-and-exams-view {
  @apply w-full pb-6 relative;
  height: calc(100% - 55px);
  .prescriptions-fixed-header {
    @apply bg-bgCanvas;
    position: sticky;
    top: 0;
    z-index: 1;
    
  }
  .content-loader {
    @apply flex w-full items-center justify-center;
    height: calc(100vh / 3);
  }
  .prescriptions-tabs {
    @apply flex justify-center;
    .prescriptions-tabs-options {
      @apply flex flex-1 justify-between max-w-xl relative;
      li {
        @apply w-1/2;
      }
      .exams-nav-item {
        .nav-item-text {
          @apply relative;
          .badge-outline {
            @apply absolute;
            right: -5px;
            top: 0;
          }
        }
      }
    }
  }
  .prescriptions-and-exams__tab-content {
    @apply w-full md:max-w-xl pb-16 flex flex-col items-center;
    z-index: 1;
    height: calc(100% - 55px);
    margin: 0 auto;
    .prescription-info {
      @apply pl-4 w-full;
      scroll-margin-top: 131px;
      .list-item-arrow {
        .anchor-container {
          .exam-options-btn {
            padding-right: 10px;
          }
        }
      }
      .download-button-container {
        padding-right: 10px;
        i {
          color: rgba(0, 0, 0, 0.87);
        }
      }
      .exam-icon-container {
        @apply absolute rounded-full text-center bottom-0 flex items-center justify-center;
        width: 24px;
        height: 24px;
        right: -10px;
      }
      .list-item-container {
        &:hover {
          background-color: transparent;
        }
        .list-item-arrow {
          margin-right: 0;
        }
        .list-item-title, .list-item-caption {
          @apply mb-1;
          word-break: break-all;
        }
        .list-item-caption, .extra-caption {
          @apply text-bodyMedium text-textSecondary;
          letter-spacing: 0.25px;
        }
      }
    }
    .prescriptions-and-exams__empty-list-view {
      @apply lg:mt-10;
    }
  }
  .options-modal-inner {
    .list-item-arrow {
      @apply pr-2;
    }
  }
  .options-modal {
    .options-modal-inner {
      .list-item-container {
        .body-container {
          margin: 1rem 0;
          .list-item-title {
            margin: 0;
          }
          .list-item-caption {
            display: none;
          }
        }
      }
      .achor-exclude-btn {
        @apply mb-4 md:mb-0;
        .list-item-divider {
          @apply md:hidden;
        }
      }
    }
  }
  .alert-snackbar {
    @apply px-4 pt-7 max-w-md;
  }
}
.confirm-delete-exam-modal__outer {
  .modal-overlay {
    z-index: 10000 !important;
    background-color: #00000099;
  }
  .confirm-delete-exam-modal__inner {
    @apply max-w-sm text-center;
    max-width: unset;
    width: 85%;
    z-index: 10001 !important;
    .header-modal {
      margin: -4px -4px 0 0;
    }
    .delete-exam-modal__img {
      @apply flex w-full justify-center -mt-7 mb-4;
    }
    .delete-exam-modal__title {
      @apply text-titleLarge text-textPrimary mb-2;
      letter-spacing: 0.15px;
    }
    .delete-exam-modal__message {
      @apply text-bodyMedium text-textSecondary mb-7;
      letter-spacing: 0.25px;
    }
  }
}

@screen md {
  .prescriptions-and-exams-view {
    .prescriptions-and-exams__tab-content {
      @apply pb-4;
    }
  }
}