.scene {
  display: inline-block;
  width: 296px;
  height: 176px;
  perspective: 600px;

  .card {
    position: relative;
    width: 100%;
    height: 100%;
    
    transform-style: preserve-3d;
    transform-origin: center right;
    transition: transform 0.6s;
  }

  .card.is-flipped {
    transform: translateX(-100%) rotateY(-180deg);
  }

  .card__face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 16px;
  }

  .background {
    background: white;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 2px 4px rgba(17, 17, 17, 0.1);
  }
  
  .card__face--front {
    .card__face__content--front {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 8px;
      
      .header {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      .footer {
        @apply text-bgBox;
        width: 100%;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
      }
    }
  }

  .card__face--loading {
    background-image: none !important;
    background-color: #F9FAFF !important;
    border: 1px solid #DADBE1;

    .card__face__content--front {
      .tooltip--placeholder {
        @apply bg-bgDisable text-bgDisable;
        height: 24px;
        width: 110px;
        border-radius: 73px;
      }
      .iconButton--placeholder {
        @apply bg-bgDisable text-bgDisable;
        height: 28px;
        width: 28px;
        border-radius: 40px;
      }
      .title--placeholder {
        @apply bg-bgDisable text-bgDisable;
        width: 100%;
        height: 24px;
        border-radius: 73px;
      }
      .icon--loading {
        @apply bg-bgDisable text-bgDisable;
        height: 16px;
        width: 16px;
        border-radius: 8px;
      }
      .text--loading {
        @apply bg-bgDisable text-bgDisable;
        height: 16px;
        width: 67px;
        border-radius: 73px;
      }
    }

    .card__face__content--back {
      background-color: #F9FAFF !important;
      .header {
        .iconButton--placeholder{
          @apply bg-bgDisable text-bgDisable;
          height: 25px;
          width: 25px;
          border-radius: 8px;
          margin: 3px;
        }
      }
      .body {
        .fontIcon--placeholder {
          @apply bg-bgDisable text-bgDisable;
          height: 31px;
          width: 31px;
          border-radius: 8px;
        }
        .button--placeholder {
          @apply bg-bgDisable text-bgDisable;
          height: 16px;
          width: 77px;
          border-radius: 73px;
          position: relative;
          margin-bottom: 21px;
          &::before {
            @apply bg-bgDisable text-bgDisable;
            content: ' ';
            height: 16px;
            width: 77px;
            top: 21px;
            left: 0;
            border-radius: 73px;
            position: absolute;
          }
        }
      }
    }
  }
  
  .card__face--back {
    @apply text-textPrimary;
    transform: rotateY(180deg);
    
    .card__face__content--back {
      height: 100%;
      width: 100%;
      backdrop-filter: blur(15px);
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 5px;
      background-color: rgba(240, 241, 251, 0.5);

      .header {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        > button {
          height: 30px;
          width: 30px;
        }
      }
      .body {
        display: flex;
        justify-content: space-between;
        padding-bottom: 35px;
        margin-top: auto;
        > button {
          &:hover {
            filter: opacity(0.8);
          }
        }
      }
    }
  }
}
