.form-multi-select-container {
  .label-text {
    @apply text-fontDefault text-textPrimary;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
  }

  .label-option-container {
    @apply text-fontDefault text-textPrimary;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    hr {
      margin-top: 9px;
    }
  }
  .new-checkbox-container {
    padding: 9px 0;

    label {
      display: flex;
      flex: 1;
      width: 100%;
    }
  }

  .multiselect-divider {
    margin-left: 36px;
  }

  .loading-label {
    @apply bg-bgDisable;
    border-radius: 16px;
    max-width: 224px;
    height: 16px;
  }
}