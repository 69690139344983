.submit-documents--loader {
  @apply flex flex-col items-center justify-center h-screen;
}

.submit-documents--form{
  @apply mx-4;
  .submit-documents--title {
    @apply text-fontSmall text-textPrimary;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
  }
  .submit-documents--button {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    .submit-btn {
      @apply mt-4;
    }
  }
}
