.feedback-container {
  height: calc(100% - 80px)
}

.health-plan-negative-profile {
  .go-up {
    -webkit-animation: animation-up-not-accepted-profile 1.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: animation-up-not-accepted-profile 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-delay: 1.5s;
    position: absolute;
    top: 110px;

    svg {
      width: 118px;
      height: 118px;
    }
  }

  .not-accepted-plan-icon {
    position: absolute;
    top: 110px;
    -webkit-animation: animation-not-accepted 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: animation-not-accepted 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    svg {
      width: 118px;
      height: 118px;
    }
  }

  .list-item {
    li {
      padding: 10px 0px;
    }
  }

  .show-content-not-accepted {
    animation: animation-content-not-accepted-profile 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    -webkit-animation: animation-content-not-accepted-profile 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-delay: 2.5s;
    max-width: 600px;
    position: absolute;
    top: 110px;
    margin-top: 114px;
  }

  @screen md {
    .accordion-wrapper {
      max-width: 600px;
      min-width: 600px;
    }

    .button-healthplan-feedback {
      width: 343px;
    }
  }

  @keyframes animation-content-not-accepted-profile {
    0% {
      opacity: 0;
      top: 110px;
    }
    100% {
      opacity: 1;
      top: 80px;
    }
  }

  @keyframes animation-up-not-accepted-profile {
    0% {
      top: 110px;
      transform: scale(1);
    }
    100% {
      top: 80px;
      transform: scale(0.7);
    }
  }

  @keyframes animation-not-accepted {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}

