.single-post__loader-container {
  @apply flex justify-center h-screen w-full bg-bgCanvas;
  .single-post__loader {
    @apply flex items-center h-full;
  }
}

.content-text {
  @apply flex flex-1 min-h-full relative;
  border-radius: 20px 20px 0 0;
  min-height: 90vh;
  overflow-y: hidden;
  overflow-x: hidden;
}

.single-post__view {
  @apply h-full w-full;
  .single-post__content {
    @apply mx-auto w-full lg:max-w-xl mt-20 px-4;
    .single-post__iframe {
      width: 100%;
      height: calc(100vh - 55px - 76px);
      border-radius: 16px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

@screen md {
  .single-post__view {
    .single-post__content {
      @apply md:mt-0;
      .single-post__iframe {
        height: calc(100vh - 93px);
      }
    }
  }
}