#pregnancy-cards-drawer {
  z-index: 10001;
  .pregnancy-card-drawer-content {
    @apply w-full h-full px-4 overflow-y-auto py-6;
    .preganancy-cards-drawer__loading {
      @apply flex w-full justify-center py-8;
    }
    .pregnancy-cards-drawer__error-msg {
      @apply mb-4;
      .snackbar-buttons-container {
        button {
          width: fit-content;
        }
      }
    }
    .pregnancy-card-drawer-subtitle {
      @apply text-titleMedium font-medium mb-2;
      letter-spacing: 0.15px;
    }
    .pregnancy-cards-drawer__previous-cards {
      @apply gap-2 mt-2;
    }
  }
}