.drawer-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
  &.rightDrawer {
    display: none;
  }
}
.drawer-material {
  position: fixed;
  background: white;
  display: flex;
  flex-flow: column;
  z-index: 10000;
  overflow-y: auto;
  overflow-x: hidden;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  &.rightDrawer {
    height: 100%;
    height: -webkit-fill-available;
    top: 0;
    bottom: 0;
    right: -100px;
    width: 100%;
    animation-duration: 0.1s;
    &.drawer-active {
      animation-name: slidefullleft;
    }
    &.drawer-inactive {
      animation-name: slidefullright;
    }
  }
  &.bottomDrawer {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40vh;
    animation-duration: 0.3s;
    &.drawer-active {
      animation-name: slideup;
    }
    &.drawer-inactive {
      animation-name: slidedown;
    }
  }
}


@screen md {
  .drawer-overlay {
    &.rightDrawer {
      display: block;
    }
  }
  .drawer-material {
    animation-duration: 0.1s;
    &.rightDrawer {
      width: 30%;
      min-width: 310px;
      height: 100%;
      top: 0;
      bottom: 0;
      left: auto;
      &.drawer-active {
        animation-name: slideleft;
      }
      &.drawer-inactive {
        animation-name: slideright;
      }
    }
  }
}

@keyframes slideup {
  0% {
    bottom: -40vh;
  }
  100% {
    bottom: 0;
  }
}

@keyframes slidedown {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -40vh;
  }
}

@keyframes slideleft {
  0% {
    right: -30%;
  }
  100% {
    right: 0;
  }
}

@keyframes slideright {
  0% {
    right: 0;
  }
  100% {
    right: -30%;
  }
}

@keyframes slidefullleft {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}

@keyframes slidefullright {
  0% {
    right: 0;
  }
  100% {
    right: -100%;
  }
}
