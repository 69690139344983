.profile-container {
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 16px;
  .profile-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    .profile-picture-container {
      margin: 0;
    }
    .user-name-style {
      @apply text-fontSmall text-textPrimary;
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: 400;
      padding-top: 16px;
    }
    .user-name-loading {
      @apply bg-bgDisable;
      border-radius: 16px;
      width: 60px;
      height: 28px;
      margin-top: 16px;
      margin-bottom: 15.5px;
    }
    .user-email {
      @apply text-fontDefault text-textDisable mb-4;
    }
  }
  .anchor-container {
    display: block;
    margin-bottom: 16px;
    .options-modal {
      left: 0;
      width: 100%;
    }
  }
}

@screen lg {
  .profile-container {
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 16px;

    .profile-data {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}