.usg-times-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(66px, 1fr));
  column-gap: 3px;

  & > * {
    margin: 8px 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.usg-days-slider {
  position: relative;
  width: 100%;
  user-select: none;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    border: 1px solid #D5D5D5;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: #EEEEEE;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #B0B0B0;
  }
}

.usg-days-right-slider-button {
  height: 80%;
  position: absolute;
  right: -15px;
  margin: auto 0;
  transition: 0.1s all;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.98));
  &:active {
    filter: brightness(-1.2);
    background: rgba(250, 250, 250, 0.9);
  }
}

.usg-days-left-slider-button {
  height: 80%;
  position: absolute;
  left: -15px;
  margin: auto 0;
  transition: 0.1s all;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.98));
  &:active {
    filter: brightness(-1.2);
    background: rgba(250, 250, 250, 0.9);
  }
}

.usg-schedule-message__have-no-timeslot {
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
