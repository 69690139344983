.schedule-confirm {
  @apply pt-4 px-10 flex flex-col items-center mt-10;
  p.text-confirm {
    @apply font-light text-primaryDark text-textMedium text-center;
    width: 100%;
  }
  p.schedule-confirm__title {
    @apply font-medium text-headlineMedium text-primaryDark mx-auto mb-6 text-center;
    width: 100%;
  }
  .confirm-button-container {
    &.full-container {
      width: 100%;
    }
    &.half-container {
      width: 100%;
    }
  }
  .error-icon {
    height: 80px;
    width: 80px;
  }
}

.logo-schedule-confirm {
  width: 90px;
}


@screen md {
  .schedule-confirm {
    margin-top: 70px;
    .schedule-confirm__title {
      width: 45%;
    }
    p.text-confirm {
      width: 45%;
    }
    .confirm-button-container {
      &.full-container {
        width: 50%;
      }
      &.half-container {
        width: 25%;
      }
    }
  }
  .logo-schedule-confirm {
    width: 160px;
  }
}