.list-chip-option-input {
  label {
    padding-bottom: 8px;
  }

  .list-chip-option-buttons {
    display: flex;
    flex-direction: row;

    .new-chip-container {
      margin-right: 8px;
      flex: 0;
    }
  }

  .loading-label {
    @apply bg-bgDisable;
    border-radius: 16px;
    max-width: 224px;
    height: 16px;
  }

  .loading-chip-option {
    @apply bg-bgDisable;
    border: 1px solid #EEEEEE;
    border-radius: 24px;
    height: 32px;
    width: 48px;
    margin-top: 12px;
  }

  .first-loading-chip {
    margin-right: 8px;
  }
}
