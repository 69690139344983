.exams-list {
  .item-from-list  {
    .list-item-container {
      .title-container {
        .list-item-caption {
          @apply w-full;
          word-break: keep-all;
          max-width: unset;
        }
      }
    }
  }
}