.professional-summary--view {
  @apply h-screen max-h-screen flex overflow-hidden;
  padding-bottom: 55px;
  font-family: 'Rubik', sans-serif;
  .professional-summary--scroll-view {
    @apply flex flex-col flex-1 overflow-y-auto;
    .back-button {
      @apply ml-6 my-6;
      min-height: 44px;
    }
    .professional-summary--header {
      .professional-summary--header-content {
        @apply px-4 flex flex-col;
        .professional-summary--professional-info {
          @apply flex items-center;
          .professional-picture {
            @apply mr-3 ml-1 border border-divider;
            width: 52px;
            height: 52px;
          }
          .professional-name {
            @apply text-primaryDark font-light text-headlineSmall;
          }
          .professional-category {
            @apply text-greyColor pt-2 font-medium text-textSmall;
          }
        }
        .schedule-btn {
          @apply mt-4;
        }
      }
    }
    .professional-summary--content {
      @apply bg-bgBox mt-6 pb-14 rounded-t-3xl flex flex-col flex-1;
      .professional-summary--biography {
        @apply text-justify pt-5 pb-6 px-4 text-base;
        p {
          @apply text-textSecondary;
        }
        h1 {
          @apply text-textPrimary font-medium;
        }
      }
    }
    .professional-summary--loader-container {
      @apply flex flex-1 justify-center items-center bg-bgCanvas;
    }
  }
}

@screen md {
  .professional-summary--view {
    padding-bottom: 0;
  }
}

@screen lg {
  .professional-summary--view {
    .professional-summary--scroll-view {
      .back-button {
        @apply mt-8;
      }
      .professional-summary--header {
        display: grid;
        grid-template-columns: repeat(12, [col-start] 1fr);
        grid-column-gap: 24px;
        grid-template-rows: auto;
        border-collapse: separate;
        .professional-summary--header-content {
          @apply px-0 flex-row;
          grid-column-start: 3;
          grid-column-end: 11;
          .schedule-btn {
            @apply mt-0 ml-auto;
          }
        }
      }
      .professional-summary--content {
        display: grid;
        grid-template-columns: repeat(12, [col-start] 1fr);
        grid-column-gap: 24px;
        grid-template-rows: auto;
        border-collapse: separate;
        .professional-summary--biography {
          grid-column-start: 3;
          grid-column-end: 11;
        }
        .professional-summary--loader-container {
          grid-column-start: 3;
          grid-column-end: 11;
          .professional-summary--loader {
            @apply flex items-center h-full;
          }
        }
      }
    }
  }
}
