.specialtys-list-container {
  @apply h-full pt-5 pb-4;

  .specialtys-list-title {
    @apply text-titleLarge font-normal text-textPrimary mb-6;
  }

  .specialtys-list-loader-container {
    @apply flex flex-col items-center justify-center h-1/3;
  }

  .snackbar-container {
    > div {
      h1 {
        &:last-child {
          @apply text-bodyMedium;
        }
      }
    }
  }
}

.blocked-specialty-modal-outer {
  .modal-container {
    @apply relative flex flex-col w-full;
    overflow: visible !important;
    padding: 16px !important;

    .blocked-specialty-modal-content {
      @apply text-center overflow-auto;

      .blocked-specialty-modal-title {
        @apply text-headlineSmall my-2;
      }

      .blocked-specialty-modal-description {
        @apply text-bodyLarge text-textSecondary mb-4;
      }
    }

    .blocked-specialty-block-icon {
      @apply bg-bgBoxSecondary border-solid border-8 border-bgBox text-textSecondary absolute rounded-full p-4 self-center;
      top: -33px;
    }
  }
}

@screen md {
  .blocked-specialty-modal-outer {
    .modal-container {
      padding: 20px !important;
    }
  }
}