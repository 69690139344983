.survey-modal-content {
  height: 100%;
  .stepper-header {
    height: 72px;
    position: relative;
    .header-icon {
      position: absolute;
      margin-left: 16px;
      top: 25%;
    }
  }
  .survey-modal-content-body {
    padding: 0 1rem;
    margin-right: 2px;
    overflow: auto;
    height: calc(100% - 72px);
    flex: 2;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &::-webkit-scrollbar {
      display: none;
    }

    .btn-container {
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 2;
      flex-grow: 2;
    }
  }
}
