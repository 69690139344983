.booking {
  position: relative;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #343434 0.00%, #6C6C6C 43.24%, #292929 100%);
}

.booking__menu {
  @apply flex items-center justify-center mb-6;
  position: absolute;
  color: white;
  bottom: 0;
  width: 100%;
  z-index: 3;
  &.to-top {
    bottom: 39vh;
  }
}

.booking__thumb {
  &.to-top {
    .participant__local-video {
      top: 25px;
    }
  }
}

.booking__header {
  position: absolute;
  z-index: 3;
  top: 0;
  color: white;
  padding: 24px;
  background: linear-gradient(180deg, #FFFFFF 0%, #535353 0.01%, rgba(255, 255, 255, 0) 100%);
  margin-bottom: 1.5rem;
  width: 100%;
}

.booking__title {
  font-size: 13px;
  line-height: 18px;
}

.booking__remote-name {
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  padding-right: 13px;
}

.booking__remote-infos {
  @apply flex flex-row;
  align-items: center;
}

.booking__loading {
  @apply flex flex-col justify-center;
  top: 50%;
  align-self: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  transform: translate(0,-50%);
  &.to-top {
    top: 33%;
  }
}

.booking__connecting {
  @apply text-center;
  font-size: 14px;
  line-height: 19px;
  color: white;
  max-width: 80vw;
  align-self: center;
}

.booking__home-button {
  @apply rounded-full text-center border-white border justify-center mt-6;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  color: white;
  padding: 10px 24px;
  width: 90%;
}

.booking__help-button {
  @apply rounded-full text-center text-fontDefault border-white border justify-center flex flex-row;
  font-weight: bold;
  line-height: 26px;
  color: white;
  padding: 10px 24px 10px 18px;
  width: fit-content;
  align-self: center;
  > i {
    margin-right: 16px;
    font-size: 20px;
    align-self: center;
  }
}

.booking__help-link {
  @apply text-fontDefault;
  color: white;
  justify-content: center;
  align-items: center;
  > a {
    font-weight: 500;
    > i {
      margin: 0 8px 0 20px;
    }
  }
}
