.validation-card-skelleton__container {
  @apply w-full p-4;
  .validation-card-skelleton__card {
    @apply w-full h-44 rounded-2xl bg-bgBox opacity-70 border border-divider;
    .validation-card-skelleton__top-content {
      @apply flex flex-1 p-4 justify-start items-center;
      .validation-card-skelleton__image {
        @apply h-10 w-10 rounded-2xl mr-4;
      }
      .validation-card-skelleton__title {
        @apply h-6 rounded-3xl w-1/2;
      }
      .validation-card-skelleton__close-btn {
        @apply h-8 w-8 rounded-full ml-auto mr-4;
      }
    }
    .validation-card-skelleton__subtitle {
      @apply h-5 rounded-3xl ml-4 w-3/4 mt-4 mb-4;
    }
    .validation-card-skelleton__divider {
      @apply w-full;
      height: 1px;
    }
    .validation-card-skelleton__action-btn {
      @apply h-4 w-1/3 md:w-1/4 rounded-2xl mx-auto mt-4;
    }
  }
}