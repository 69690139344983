.chat-view {
  @apply w-full pb-6;
  max-height: calc(100vh - 76px);
  margin-top: 76px;
  .chat-content {
    @apply flex flex-col items-start relative lg:p-4 lg:flex-row lg:px-24 lg:pt-14 pb-6;
    max-height: calc(100vh - 76px - 55px);
    overflow-y: auto;
    .chat-left-content {
      @apply flex flex-col flex-initial pb-6;
      .top-text {
        @apply px-4 pt-6 lg:p-0;
        .title {
          @apply text-textPrimary text-headlineSmall
          md:text-fontMedium;
        }
        .subtitle {
          @apply text-textSecondary my-4 lg:mb-6;
        }
        .span {
          @apply text-textPrimary font-normal text-base mt-2 mb-4;
        }
      }
      .chat-stores {
        @apply flex flex-col items-center justify-center gap-3
        md:flex-row md:gap-12
        lg:justify-start lg:items-start lg:gap-5;
        a {
          @apply mx-auto md:mx-0 md:w-1/2;
          width: 80%;
          max-width: 210px;
        }
      }
      .chat-list {
        margin-top: -140px;
        background: #F9FAFF;
        padding-top: 20px;
        border-top: 1px solid #EEEEEE;
        left: 0;
        .item-list {
          @apply w-full flex justify-start items-center;
          p {
            @apply text-textSecondary w-full pt-4;
          }
        }
      }
    }
    .mobile-chat-image {
      @apply flex-1;
      margin-top: 20px;
      img {
        margin: 0 auto;
        max-height: 37.5rem;
        max-width: 20rem;
      }
    }
  }
}

@screen md {
  .chat-view {
    margin-top: 0;
    .chat-content {
      max-height: unset;
    }
  }
}

@screen lg {
  .chat-view {
    .chat-content {
      .chat-left-content {
        max-width: 60%;
        .chat-list {
          margin-top: 0px;
          border-top: none;
        }
      }
      .mobile-chat-image {
        margin-top: 0px;
        img {
          margin: 0;
          margin-left: 50px;
          min-width: 150px;
          max-width: 100%;
        }
      }
    }
  }
}