.modal__usg__background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, .5);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal__usg__card{
    height: 100%;
    width: 100%;
    overflow: hidden;
    
    .modal__usg__layout {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      
      .modal__usg__close-button {
        @apply bg-bgButton text-textPrimary;
        position: absolute;
        top: 0.625rem;
        right: 0.375rem;
        z-index: 10001;
        opacity: 0.85;
        border-radius: 41px;
        padding: 8px 12px;
        font-size: 0.875rem;
        line-height: 1rem;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      img {
        width: 100%;
        max-height: 38%;
        object-fit: cover;
        object-position: 50% 50%;
      }
      .modal__usg__scroll {
        overflow: auto;
      }
      .modal__usg__content {
        @apply bg-white shadow;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        padding: 1.5625rem 2.1875rem;
        min-height: fit-content;
        position: relative;
        max-height: 70%;
        flex-grow: 1;
        margin-top: -20px;

        .modal__usg__content--title {
          @apply text-textPrimary;
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 1.5rem;
          text-align: center;
          margin-bottom: 1.375rem;
        }
        .modal__usg__content--subtle {
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.5rem;
        }
        .modal__usg__content--objective {
          @apply text-textSecondary;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1rem;
          margin-top: 0.5625rem
        }
        .modal__usg__content--date {
          @apply text-textSecondary;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.25rem;
          margin-top: 0.25rem;
        }
        .modal__usg__content--obs {
          @apply text-textSecondary;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1rem;
          margin-top: 0.5rem;
        }
        .modal__usg__content__dropdown {
          display: flex;
          flex-direction: column;
          margin-left: -1.1875rem;
          margin-right: -1.1875rem;
          margin-bottom: 2rem;
        }        
      }
      .modal__usg__realizado {
        justify-content: space-evenly;
        .usg__realizado__icon {
          @apply bg-bgButton;
          border: 8px solid #FFF;
          padding: 10px 10px 10px 16px;
          border-radius: 50%;
          top: -35px;
          width: 5.625rem;
          height: 5.625rem;
          align-self: center;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          z-index: 10002;
        }
        .modal__usg__content--title {
          padding-top: 7%;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .modal__usg__background {
    .modal__usg__card{
      min-height: fit-content;
      max-height: 35.625rem;
      min-width: 50vw;
      max-width: 46.875rem;
      border-radius: 20px;
      
      .modal__usg__layout {
        flex-direction: row-reverse;
        
        .modal__usg__close-button {
          top: 0.9375rem;
          right: 0.625rem;
        }
        img {
          width: 50%;
          max-height: 100%;
          height: 100%;
          object-position: 50% 50%;
        }
        .modal__usg__content {
          width: 50%;
          min-height: fit-content;
          max-height: 100%;
          border-top-right-radius: 0;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          padding: 2rem 2rem 1.5rem;
          margin-top: 0px;
  
          .modal__usg__content--title {
            margin-bottom: 2rem;
          }
          .modal__usg__content--objective {
            margin-top: 0.125rem
          }
          .modal__usg__content--obs {
            font-size: 0.875rem;
          }
          .modal__usg__content__dropdown {
            margin-left: 0;
            margin-right: 0;
          }
        }
        .modal__usg__realizado {
          .usg__realizado__icon {
            display: none;
            visibility: hidden;
          }
          .modal__usg__content--title {
            padding-top: 0;
          }
        }
      }
    }
  }
}
