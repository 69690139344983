.choose-specialist-container {
  @apply pb-16;
  .choose-specialist-main-title {
    @apply text-titleLarge font-normal text-textPrimary mb-6 text-left;
  }
  .choose-specialist-small-title {
    @apply text-bodyLarge font-normal text-textPrimary text-left;
  }
  .choose-specialist-subtitle {
    @apply text-bodyMedium font-normal text-textSecondary text-left mb-4;
    letter-spacing: 0.25px;
  }
}