.specialist-chip-container {
  @apply bg-bgCanvas flex rounded-2xl p-4 m-4 items-center;
  font-family: 'Rubik', sans-serif;

  &.button {
    &:hover:enabled {
      @apply bg-overlayLight;
    }
    &:focus:enabled {
      border: 1px solid #9A4178;
    }
    &:focus-visible:enabled {
      border: 1px solid #9A4178;
    }
  }

  .specialist-chip-picture {
    @apply mr-4;
  }
  .specialist-chip-professional-info {
    @apply flex flex-col justify-center flex-1;
    .specialist-chip-right-container-header {
      @apply flex flex-1;
      .specialist-chip-professional-tag {
        @apply text-fontDefault text-textPrimary font-normal not-italic text-left flex-1 capitalize;
      }
      .specialist-chip-professional-value {
        @apply text-fontDefault text-textPrimary font-normal not-italic text-right;
      }
    }
    .specialist-chip-professional-caption {
      @apply text-fontExtraSmall text-textSecondary font-normal not-italic text-left;
    }
    .specialist-chip-professional-name {
      @apply text-fontDefault text-textPrimary font-medium not-italic pt-2 text-left;
    }
    .extra-caption-style {
      @apply pt-1;
    }
  }
}









