.header-content {
  padding: 26px;
  flex-flow: column;
  svg.header-content-logo {
    display: block;
    width: 30%;
    max-width: 9rem;
    min-width: 6rem;
  }
  .header-content-left {
    order: 1;
    width: 100%;
  }
  .header-content-text {
    order: 2;
    margin-top: 20px;
    h1 {
      @apply text-textPrimary text-headlineSmall;
      letter-spacing: 0.15px;
    }
  }
}

.header-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-content-without-logo {
  padding: 16px 8px;
  flex-flow: row;
  align-items: center;
  .header-content-left-without-logo {
    order: 2;
  }
  .header-content-text-without-logo {
    order: 1;
    margin-top: 0;
    h1 {
      @apply text-textPrimary text-headlineSmall;
      letter-spacing: 0.15px;
    }
  }
}

.header-loading-container {
  display: flex;
  flex-direction: row;

  .header-loading-button {
    @apply bg-bgDisable;
    border-radius: 8px;
    width: 24px;
    height: 24px;
  }
  
  .header-loading-title {
    @apply bg-bgDisable;
    border-radius: 8px;
    height: 24px;
    width: 107px;
    margin-left: 8px;
  }
}

@screen md {
  .header-content {
    flex-flow: row;
    svg.header-content-logo {
      display: none;
      width: auto;
    }
    .header-content-text {
      order: 1;
      margin-top: 0;
      h1 {
        @apply text-fontMedium;
      }
    }
    .header-content-left {
      order: 2;
      width: auto;
    }
  }

  .header-content-without-logo {
    padding: 16px;
    .header-content-text-without-logo {
      h1 {
        @apply text-fontMedium;
      }
    }
  }
}