.anchor-container {
  display: block;
  width: 100%;

  .options-modal-inner {
    .btn-material.text.btn-delete-payment {
      color: #BB3949;
    }

    .btn-material.btn-delete-payment.text.disabled {
      color: rgba(0, 0, 0, 0.42);
    }
  }
}

