.label-medium-radio-group-list {
  @apply text-textPrimary text-fontDefault;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  left: 0;
}
.radio-group-list {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.radio-group-divider {
  margin-left: 30px;
  margin-bottom: 16px;
}

.radio-group-extra-component {
  margin-bottom: 16px;
}