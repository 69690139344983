.my-bookings-view-container {
  @apply w-full;
  .my-bookings-tab {
    @apply flex justify-center items-center;
    margin-top: 76px;
    ul {
      @apply max-w-xl;
    }
    li {
      @apply flex flex-1;
    }
  }
  .my-bookings-body {
    @apply py-8;
    overflow-y: auto;
    max-height: calc(100vh - 55px - 76px - 60px);
  }
}

@screen md {
  .my-bookings-view-container {
    height: 100vh;
    .my-bookings-tab {
      margin-top: 0;
    }
    .my-bookings-body {
      max-height: unset;
    }
  }
}
