.input-group-container { 
  position: relative;
  .input-group { 
    width: 100%;
  }
  .input-icon-form { 
    position: absolute;
    top: 41.5px; 
    right: 12px;
    z-index: 10;
  }
}

.input-group { 
  position: relative;
  display: grid;

  input {
    @apply text-textPrimary text-fontDefault;
    display: block;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.6);
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    margin-top: 8px;
    padding: 12px 16px;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    outline: none;

    &:hover:enabled {
      border: 1px solid rgba(0, 0, 0, 0.87);
    }
    &:focus:enabled {
      border: 1px solid #9A4178;
    }
    &:focus-visible {
      border: 1px solid #9A4178;
    }
    &:disabled {
      @apply bg-white text-textDisable;
      border: 1px solid rgba(0, 0, 0, 0.42);
    }
    &.error {
      border: 1px solid #FF7951;
    }
    &:-webkit-autofill {
      background: #FFFFFF;
    }
  }
  input[value=""] {
    border: 1px solid #EEEEEE;

    &:hover:enabled {
      border: 1px solid rgba(0, 0, 0, 0.6);
    }
    &:focus:enabled {
      border: 1px solid #9A4178;
    }
    &:focus-visible {
      border: 1px solid #9A4178;
    }
    &:disabled {
      @apply bg-white text-textDisable;
      border: 1px solid #EEEEEE;
    }
    &.error {
      border: 1px solid #FF7951;
    }
  }
  ::placeholder {
    @apply text-textSecondary text-fontDefault;
    font-family: 'Rubik', sans-serif;
    font-style: italic !important;
    font-weight: normal;
  }

  label {
    @apply text-textPrimary text-fontDefault;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  .label-medium {
    @apply text-textPrimary text-fontDefault;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  .is-danger {
    border-bottom: 1px solid #E5495F;
  }

  .help {	
    color: #E5495F;	
    font-size: 12px;	
  }
  
  .form-input-icon-container {
    padding-right: 35px;
  }
}

.new-input-group { 
  position: relative;
  display: grid;
  height: 60px;

  input {
    display: block;
    width: 100%;

    background: transparent;
    color: #333333;
    font-weight: 600;

    border: 1px solid #585858;
    border-radius: 5px;
    width: -webkit-fill-available;
    padding: 10px;
    margin-bottom: 3px;
    height: 40px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  input:focus { outline: none; }

  input:focus ~ label, input:valid ~ label, .label-normal {
    top: -20px;
    font-size: 14px;
    color: #828282;
  }

  .label-error {
    top: -20px;
    font-size: 14px;
  }

  .is-danger {
    border: 1px solid #E5495F;
  }

  .help {	
    color: #E5495F;	
    font-size: 12px;
    padding-bottom: 10px;	
  }

  ::placeholder {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #6E6D6D;
  }
}

.StripeElement {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  line-height: 24px;
  display: block;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
  margin-top: 8px;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: normal;
  outline: none;
  padding: 12px 16px;
}
.StripeElement--empty {
  border: 1px solid #EEEEEE;
}
.StripeElement:hover {
  border: 1px solid rgba(0, 0, 0, 0.6);
}
.StripeElement--focus {
  border: 1px solid #9A4178;
}
.StripeElement--webkit-autofill, .StripeElement--complete {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.6);
}
.StripeElement--invalid {
  border: 1px solid #FF7951;
}