.profile-picture {
  border-radius: 60px;
  position: relative;
  justify-content: center;
}

.user-name-loading {
  @apply bg-bgDisable;
  border-radius: 16px;
  width: 60px;
  height: 28px;
  margin-top: 16px;
  margin-bottom: 15.5px;
}

@keyframes loading {
  0% {
    background-color: hsl(231deg, 10%, 87%);
  }
  100% {
    background-color: hsl(231deg, 10%, 97%);
  }
}

.progress-ring {
  position: absolute;
  top: -11px;
  right: 1px;
}

.progress-ring__circle {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(55deg);
  transform-origin: 50% 50%;
}

.edit-profile-button {
  background: rgb(255, 255, 255);
  color: #E5495F;
  box-shadow: 0px 5px 8px rgba(47, 72, 149, 0.1);
  border-radius: 16px;
  width: 32px;
  height: 32px;
  display: block;
  bottom: 3px;
  position: absolute;
  outline: none;
  right: 10%;
}

.profile-title__name {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #333333;
}

.profile-title__email {
  font-size: 14px;
  line-height: 26px;
  color: #4F4F4F;
}
.user-info-profile {
  .circle-separator {
    @apply rounded-full bg-darkGreyColor;
    height: 6px;
    width: 6px;
    align-self: center;
    margin-left: 6px;
    margin-right: 6px;
  }
}
