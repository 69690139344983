.user-profile-infos-container {
  overflow-x: hidden;
  .user-profile-infos-body-outer-container {
    display: flex;
    flex-direction: column;
  }

  .user-profile-infos-body-inner-container {
    width: 100%;
  }

  .shared-container {
    display: flex;
    flex-direction: column;
    padding: 24px 16px 0 16px;

    .weigth-container {
      margin-top: 16px;
    }
  }

  .individual-container {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0 16px;
  }

  .button-container {
    margin: 28px 16px 16px 16px;
  }

  .banner-small-screen {
    display: block;
  }

  .banner-large-screen {
    display: none;
  }
}

@screen lg {
  .user-profile-infos-container {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-column-gap: 24px;
    grid-template-rows: auto;

    .form-multi-select-container {
      .multiselect-divider {
        width: 95% !important;
      }
    }

    .header-container {
      grid-column-start: 1;
      grid-column-end: 13;
    }

    .user-profile-infos-body-outer-container {
      grid-column-start: 3;
      grid-column-end: 12;
    }

    .user-profile-infos-form-and-explanation-container {
      display: grid;
      grid-template-columns: repeat(9, [col-start] 1fr);
      grid-column-gap: 24px;
      grid-template-rows: auto;
    }

    .user-profile-infos-form-container {
      grid-column-start: 1;
      grid-column-end: 7;
    }

    .banner-with-explanation-container {
      grid-column-start: 7;
      grid-column-end: 10;
      margin: 24px 0 0 0;
    }

    .shared-container {
      display: flex;
      flex-direction: row;
      padding: 24px 0 0 0 !important;


      .last-period-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 22px;
        width: 330px;
      }
  
      .weigth-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 330px;
        margin-top: 0;
      }
    }
    .button-container {
      margin: 28px 0;
      max-width: 328px;
    }
    .banner-small-screen {
      display: none;
    }
  
    .banner-large-screen {
      display: block;
    }
  }
  .individual-container {
    padding: 16px 0 0 0 !important;
  }
}