.schedule-payment {
  padding: 40px;
  height: 100%;
  &::-webkit-scrollbar {
    display: block;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-top: 50px;
    border: 1px solid rgba(160, 112, 112, 0.2);
    box-sizing: border-box;
  }

  &::-webkit-scrollbar-thumb {
    background: #C7C7C7;
    border-radius: 10px;
  }
  #card-element {
    border: 1px solid #585858;
    border-radius: 5px;
    padding: 10px 10px 30px;
  }

  #card-element--webkit-autofill {
    background-color: #FEFDE5 !important;
  }

  #card-errors {
    color: #E5495F;
    font-size: 12px;
  }

  .payment-title {
    @apply text-primaryDark;
    font-size: 24px;
    line-height: 28px;
    text-align: left;
    width: 80%;
    font-family: "Rubik", sans-serif;
  }

  .payment-subtitle {
    @apply text-darkGreyColor;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    width: 80%;
    font-family: "Rubik", sans-serif;
  }

  .payment-value {
    @apply text-primaryDark;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-top: 20px;
    text-align: center;
    font-family: "Rubik", sans-serif;
  }

  .old-payment-value {
    text-decoration: line-through;
  }

  .new-payment-value {
    @apply text-primaryDark;
  }

  .promotionalCodeError {
    @apply text-error;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    width: 80%;
    padding-top: 12px;
    font-family: "Rubik", sans-serif;
  }

  input:-internal-autofill-selected {
    font-family: "Rubik", sans-serif;
    color: #717172 !important;
  }
  .stripe-payment {
    .select__options {
      font-family: "Rubik", sans-serif;
      border-radius: inherit;
      border-radius: 5px;
      border: 1px solid #585858 !important;
      padding: 10px;
      color: #717172;
      font-weight: 600;
      font-size: 18px;
      background-size: 15px;
      background-position: right 20px top 20px;
      background-image: url('data:image/svg+xml;utf8,<svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 4.29693L9.74902 0.206617C10.0352 -0.0688728 10.4992 -0.0688728 10.7854 0.206617C11.0715 0.482107 11.0715 0.928764 10.7854 1.20425L6.01817 5.79338C5.73199 6.06887 5.26801 6.06887 4.98183 5.79338L0.214634 1.20425C-0.0715447 0.928764 -0.0715447 0.482107 0.214634 0.206617C0.500813 -0.0688729 0.964801 -0.0688729 1.25098 0.206617L5.5 4.29693Z"  fill="%232F4895"/></svg>');
    }

    label,
    .label-error {
      opacity: 0.8;
      font-size: 13px;
      color: #828282;
      font-weight: bold;
      font-family: "Rubik", sans-serif;
    }
    .input-group input,
    input,
    input:focus,
    input:active {
      color: #717172;
      opacity: 0.8;
      font-size: 18px;
    }
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus,
    select:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    .input-group {
      input {
        font-family: "Rubik", sans-serif;
        border: 1px solid #585858;
        border-radius: 5px;
        font-weight: 600;
        padding: 10px;
        color: #585858;
      }
      ::placeholder {
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        align-items: center;
        color: #585858;
      }
      .help {
        color: #E5495F;
      }
    }
    .form-text {
      font-family: "Rubik", sans-serif;
      font-size: 14px;
      line-height: 1.6;
      color: #585858;
    }

    .stripe-error-text {
      font-family: "Rubik", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      color: #E5495F;
      text-align: center;
      padding: 15px;
    }
  }

  .help {
    color: #E5495F;
    font-size: 12px;
  }

  .hidden-content {
    display: none;
  }
  .show-content {
    display: block;
  }

  .stripe-payment-success {
    background: linear-gradient(180deg, #ECECF7 0%, #F9F9FC 9.37%, #F9F9FC 63.02%, #F9F9FC 100%);
    overflow-x: auto;
  }

  .confirm-btn {
    background: linear-gradient(172.28deg, #E5495F 2.5%, #2F4895 97.5%);
    border-radius: 15px 0px 15px 15px;
    width: 100%;
  }

  .schedule-information {
    @apply text-primaryDark;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin: 20px 20px 24px 20px;
  }

  .jump-payment {
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #2F4895;
    width: 100%;
    margin-top: 1rem;
  }
  .btn-disabled {
    opacity: 0.4;
  }
}

.payment-form-card-infos {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  .payment-form-card-expiry {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .payment-form-card-cvc {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 0;
  }
}
.payment-form-card-label {
  @apply text-textPrimary text-fontDefault;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
}
.errors-container {
  display: none;
  align-items: center;
  padding-top: 5px;
}
.element-errors {
  @apply text-fontExtraSmall text-textSecondary;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: normal;
  padding-left: 8px;
}

.show-errors-element {
  display: flex;
}

.hide-errors-element {
  display: none;
}

@media (max-height: 568px) {
  .schedule-information {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #2F4895;
    width: 85%;
    margin: 3% 0 5px 8%;
  }

  .jump-payment {
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #2F4895;
    width: 100%;
  }
}

@screen md {
  .payment-form-card-infos {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    .payment-form-card-expiry {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .payment-form-card-cvc {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 0px;
    }
  }
}

@screen lg {
  .schedule-payment {
    &::-webkit-scrollbar-track {
      margin-top: 100px;
    }
    .payment-title {
      text-align: center;
      width: 100%;
    }
    .payment-subtitle {
      text-align: center;
      width: 100%;
    }
  }
  .stripe-payment {
    padding: 0% 5% 5% 5% !important;
  }
}
