.welcome-pediatric-modal-container {
  height: 100%;
  .welcome-pediatric-modal-body {
    padding: 0 32px;
    margin-right: 2px;
    overflow: auto;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    .welcome-pediatric-modal-body h2 {
      font-size: 22px;
    }

  }

  .btn-modal-pediatric-container {
    margin-bottom: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    flex: 1;
    .later-pediatric-modal-reminder {
      display: flex;
    }
  }
}

@media (min-width: 768px) {
  .welcome-pediatric-modal-container {
    .btn-modal-pediatric-container {
      .later-pediatric-modal-reminder {
        display: none;
      }
    }
  }
}
