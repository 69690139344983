.child-healthplan-view {
  @apply h-full flex flex-col flex-1 flex-grow mx-auto justify-between pb-16 md:justify-start md:pb-10;
  .healthplan-body {
    @apply flex flex-col flex-1 md:flex-none;
    .title {
      @apply text-titleLarge text-textPrimary max-w-xs mb-4 md:max-w-none;
      letter-spacing: 0.15px;
    }
    .chips-container {
      @apply flex gap-2 lg:gap-6 2xl:gap-10;
    }
    .form-container {
      @apply mt-4 flex-1;
    }
  }
  .continue-btn {
    @apply mt-4;
  }
}