.generic-payment-title {
  text-align: left;
  width: 80%;
}

.schedule-checkout-title {
  @apply text-textPrimary text-textMedium;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
}

.generic-payment-subtitle {
  text-align: left;
  width: 75%;
  font-weight: 300;
}

.payment-container {
  min-height: 100%;
  overflow: hidden;
  .payment-container-button {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .payment-change-type {
    margin: 16px 0 70px 0;
  }

  .sales-onboarding-container {
    padding: 0;
  }

  .payment-value {
    @apply text-primaryDark;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-top: 20px;
    text-align: center;
    font-family: 'Rubik', sans-serif;
  }

  .old-payment-value {
    text-decoration: line-through;
  }

  .new-payment-value {
    @apply text-primaryDark;
  }

  .promotionalCodeError {
    @apply text-error;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    width: 80%;
    padding-top: 12px;
    font-family: 'Rubik', sans-serif;
  }

  .generic-stripe-payment {
    margin-bottom: 50px;
    .form-text{
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      line-height: 1.6;
      color: #585858;
    }

    .stripe-error-text {
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      color: #E5495F;
      text-align: center;
      padding: 15px;
    }
  }
  
  .help {	
    color: #E5495F;	
    font-size: 12px;	
  }
  
  .hidden-content {
    display: none;
  }
  .show-content {
    display: block;
  }
  
  .stripe-payment-success {
    background: linear-gradient(180deg, #ECECF7 0%, #F9F9FC 9.37%, #F9F9FC 63.02%, #F9F9FC 100%);
    overflow-x: auto;
  }
  
  .confirm-btn {
    background: linear-gradient(180deg, #B14582 0%, #9A4178 100%);
    border-radius: 15px 0px 15px 15px;
    width: 100%;
  }
  
  .schedule-information {
    @apply text-primaryDark;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin: 20px 20px 0px 20px;
  }
  
  .jump-payment {
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #723965;
    width: 100%;
    margin-top: 1rem;
  }
  .btn-disabled {
    opacity: 0.4;
  }
}
.generic-payment-form {
  position: relative;
  margin-top: 16px;
}

@media (max-height: 568px) {
  .schedule-information {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #723965;
    width: 85%;
    margin: 3% 0 0 8%;
  }
    
  .jump-payment {
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #723965;
    width: 100%;
  }

  .generic-stripe-payment {
    padding: 0% 0% 5% 0% !important;
  }
}

@screen lg {
  .generic-payment-title {
    text-align: center;
    width: 100%;
  }
  .generic-payment-subtitle {
    text-align: center;
    width: 100%;
  }
  .generic-stripe-payment {
    padding: 0% 0% 5% 0% !important;
  }
}