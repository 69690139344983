.day-and-time-slots-container {
  @apply relative;
  &.schedule-flow {
    @apply -mt-4;
    .slots-header-gradient {
      top: 5rem;
    }
  }
  .slots-header-gradient {
    @apply flex sticky z-10 pb-8 p-4 w-full gap-2 top-0 h-20;
    background: linear-gradient(to bottom, #F9FAFF 80%, transparent 100%);
    .chip-container {
      &:last-child {
        min-width: 216px;
      }
      .new-chip-container {
        width: fit-content !important;
        > i {
          margin-left: 0 !important;
        }
      }
    }
  }
  .slots-list-body {
    @apply px-4;
    .exact-day-message-container {
      @apply mb-6;
      p {
        @apply text-bodyLarge font-normal;
      }
      strong {
        @apply font-medium;
      }
    }
    .weekday-container {
      @apply mb-6;
      &:last-child {
        @apply mb-4;
      }
      .weekday-container-header {
        @apply flex justify-between items-center mb-3;
        h6 {
          @apply text-bodyLarge text-textPrimary;
        }
        p {
          @apply text-bodySmall text-textSecondary;
        }
      }
      .slots-container {
        @apply grid gap-2;
        grid-template-columns: repeat(auto-fill, minmax(62px, 1fr));
        .slot-chip {
          @apply flex items-center justify-center text-center h-8 text-labelMedium mr-auto;
        }
      }
    }
    .blocked-slots-message {
      @apply flex fixed z-20 bottom-4 right-0 left-0 px-4 md:max-w-lg md:bottom-8 md:right-8 md:left-auto;
    }
  }
  .show-more-slots-btn {
    @apply px-4;
  }
  .slots-bottom-gradient {
    @apply h-4 fixed z-10 bottom-0 w-full;
    background: linear-gradient(to bottom, transparent, #F9FAFF 80%);
  }
}
.specialists-filter-modal {
  @apply pt-1 pb-4 h-full flex flex-col justify-between;
  .specialists-checkbox-list {
    @apply pb-6 overflow-auto h-full;
  }
  .specialist-filter-btn {
    @apply p-4;
    background: linear-gradient(to bottom, transparent, #F9FAFF 80%);
  }
}
.specialists-snackbar--error-container {
   @apply p-4 mt-4;
}
.specialists-modal--loader {
  @apply flex flex-col items-center justify-center h-1/3;
}
.availabilities-loader {
  @apply flex flex-col justify-start items-start gap-y-6 pt-5 p-4;
}

@screen md {
  .day-and-time-slots-container {
    &.schedule-flow {
      @apply -mt-12;
      .slots-header-gradient {
        @apply pt-6 pb-8;
        top: 6rem;
      }
    }
  }
}