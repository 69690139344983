.schedule-review-and-confirm-container {
  .schedule-review-and-confirm-content {
    @apply flex flex-col;
    .schedule-review-title {
      @apply text-textPrimary text-headlineSmall;
    }
    .schedule-review-subtitle {
      @apply text-textPrimary text-fontDefault mt-4 mb-2;
    }
    .schedule-review-summary {
      @apply bg-bgBox -mx-4 border-t border-b border-divider overflow-hidden mb-4;
      .schedule-review-professional-picture {
        @apply rounded-2xl h-12 w-12 mr-4 ml-8 md:ml-4;
      }
      .list-item-container {
        .list-item-title {
          @apply mt-1;
        }
        .list-item-icon-container {
          @apply pl-8 md:pl-4;
        }
        .body-container {
          @apply pr-8 md:pr-4;
        }
      }
    }
    .list-item-card #health-ins-id {
      .list-item-container:hover {
        @apply bg-bgBox;
      }
    }
  }
  .schedule-review-and-confirm-button {
    @apply mt-4;
  }
  .schedule-confirm-payment-card {
    @apply -mx-4 rounded-none border-t border-b border-divider ;
  }
}

@screen md {
  .schedule-review-and-confirm-container {
    .schedule-review-and-confirm-content {
      .schedule-review-summary {
        @apply border-none rounded-2xl mx-0;
      }
    }
    .schedule-confirm-payment-card {
      @apply mx-0 rounded-2xl border-none;
    }
  }
}