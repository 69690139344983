.address-form {
  .button-confirm {
    padding-bottom: 80px;
  }
  .list-chip-option-input {
    .list-chip-option-buttons {
      .new-chip-container {
        width: 92px;
        min-width: 92px;
      }
    }
  }
  .input-group input[type="date"] {
    ::-webkit-datetime-edit {
      color: transparent;
    }
  }
  .label-medium-city-list {
    @apply text-textPrimary text-fontDefault;
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: normal;
    left: 0;
  }
  .city-list {
    display: flex;
    flex-direction: column;
  }
  .edit-profile-picture {
    margin-bottom: 7rem;
    button {
      outline: none;
    }
  }
  .profile-edit-form {
    box-shadow: 0px 5px 8px rgba(47, 72, 149, 0.1);
    border-radius: 10px;
  }

  .loader-profile-picture {
    max-width: 107px;
  }

  .edit-image-button {
    background: rgb(255, 255, 255);
    color: #E5495F;
    border-radius: 9999px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0.25rem;
    margin-right: 1.25rem;
    box-shadow: 0px 5px 8px rgba(47, 72, 149, 0.1);
    border-radius: 16px;
    width: 32px;
    height: 32px;
    display: block;
    bottom: -74px;
    position: absolute;
    left: 70px;
    outline: none;
  }
  .input-group input:disabled {
    background-color: #EEEEEE;
    border-color: #DADBE1;
  }
  .new-checkbox-container {
    padding-bottom: 0;
  }
  .form-cep-input-container {
    .cep-container {
      display: flex;
      flex-direction: column;
      align-items: left;

      .cep-input-container {
        display: flex;
        flex: 1;
        max-width: 100%;
        position: relative;

        .input-group {
          flex: 1;
        }
      }
      .input-icon {
        position: absolute;
        margin-top: 8px;
        top: 38px;
        right: 12px;
      }

      .btn-material {
        margin-top: 10px;
        margin-left: 0;
      }
      .label-btn-material {
        @apply text-fontExtraSmall;
        text-align: left;
        margin-left: 0;
      }
    }

    .city-state-container {
      display: flex;
      flex-direction: column;
      padding-top: 0px;

      .city-container {
        max-width: 100%;
        margin-right: 0;
      }

      .state-container {
        max-width: 100%;
      }
    }

    .number-complement-container {
      display: flex;
      flex-direction: column;
      padding-top: 0px;

      .number-container {
        max-width: 100%;
        margin-right: 0;
      }

      .complement-container {
        flex: 1;
      }
    }

    .name-container {
      margin-top: 16px;
    }
  }
}

.add-child-text {
  @apply text-primaryDark;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}

@screen lg {
  .form-cep-input-container {
    .cep-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;

      .cep-input-container { 
        max-width: 100%;
        width: 100%;
      }

      .btn-material {
        align-self: flex-end;
      }
    }
    .number-container {
      display: flex;
      align-items: flex-end;

      div {
        width: 50%;
      }

      .input-group {
        margin-right: 16px;
      }
      .new-checkbox-container {
        margin-left: 16px;
      }
    }
  }
}
