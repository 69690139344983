.moment-alert-modal-outer {
  .modal-overlay {
    z-index: 10001;
  }
  .moment-alert-modal {
    padding: 0 !important;
    z-index: 10002 !important;
    .header-modal {
      @apply m-0 p-4;
      margin-top: 0;
    }
    .moment-alert-body-content {
      @apply px-4 pb-8;
      .moment-alert-icon {
        @apply flex justify-center mb-4;
      }
      .moment-alert-text {
        @apply text-titleMedium font-medium text-center px-3 mb-4;
      }
      .snackbar-container {
        @apply mb-4 mx-1;
        h1 {
          &:first-child {
            font-weight: 700 !important;
          }
          &:last-child {
            @apply text-textPrimary;
          }
        }
      }
      .newest-checkbox-container {
        @apply mx-3;
      }
    }
    .moment-alert-modal-footer {
      @apply flex justify-between p-4 gap-4 border-t border-divider;
    }
  }
}