.congrats-pregnancy-modal {
  .congrats-pregnancy-width-modal.modal-container {
    width: 100%;
    max-width: 100%;
    z-index: 10001;
  }

  .modal-overlay {
    z-index: 10000;
  }

  .modal__inner {
    width: 100%;
    max-width: 100%;
    height: 90%;
    animation-duration: 0.3s;
    border-radius: 20px 20px 0 0;
    &.active {
      animation-name: slideup;
    }
    &.inactive {
      animation-name: slidedown;
    }
  }

  .congrats-pregnancy-icon {
    margin: 16px 0;
    display: flex;
    flex: 1;
    justify-content: center;
  }
}

@keyframes slideup {
  0% {
    bottom: -90%;
  }
  100% {
    bottom: 0;
  }
}

@keyframes slidedown {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -90%;
  }
}

.congrats-pregnancy-title {
  @apply text-textPrimary text-fontSmall;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  padding: 0 0 10px 0;
}

.congrats-pregnancy-caption {
  @apply text-textPrimary text-fontDefault;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  padding: 0 0 24px 0;
}

.congrats-pregnancy-first-button {
  margin-bottom: 20px;
}

.congrats-pregnancy-button-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  .congrats-pregnancy-cancel-button {
    flex: 1;
    display: flex;
    .btn-material {
      &.text {
        @apply text-textSecondary;
      }
    }
  }
  .congrats-pregnancy-confirm-button {
    display: flex;
    flex: 1;
  }
}

@screen md {
  .congrats-pregnancy-modal {
    .congrats-pregnancy-width-modal.modal-container {
      width: 100%;
      max-width: 448px;
      z-index: 10001;
      height: auto;
    }
    
    .modal-overlay {
      z-index: 10000;
    }
    
    .modal__inner {
      position: relative;
      border-radius: 20px;
      height: auto;

      &.active {
        animation-name: none;
      }
      &.inactive {
        animation-name: none;
      }
    }
  }
}