.select-profile__view {
  @apply w-full flex flex-col items-center justify-center pt-10 md:pt-4 relative z-10;
  height: 100vh;
  .select-profile__title {
    @apply w-full px-6 text-headlineSmall md:text-center mb-4;
    letter-spacing: 0.2px;
  }
  .select-profile__loader {
    @apply flex flex-1 items-start pt-20 md:flex-none;
  }
  .select-profile__error {
    @apply flex-1 p-4 md:flex-none;
  }
  .select-profile__profiles-wrapper {
    @apply flex flex-1 w-full justify-center overflow-y-auto md:flex-none;
    max-height: calc(100vh - 76px);
    .select-profile__profile-options {
      @apply grid pt-2 my-auto md:my-0;
      grid-template-columns: repeat(2, auto);
      .select-profile__profile-card-btn {
        @apply flex flex-col items-center justify-start m-2;
        max-width: 110px;
        .profile-photo {
          &.selected {
            @apply border border-primary;
            border-width: 2px;
          }
          border-radius: 30px;
        }
        .profile-name {
          @apply text-labelMedium font-medium mt-2;
          letter-spacing: 0.1px;
        }
      }
    }
  }
  .full-screen-loader {
    @apply fixed z-20 w-screen flex items-center justify-center top-0 bottom-0;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.7);
  }
}

@screen md {
  .select-profile__view
  .select-profile__profiles-wrapper
  .select-profile__profile-options {
    grid-template-columns: repeat(4, auto);
  }
}
