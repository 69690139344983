body {
  @apply bg-bgBar;
  min-height: calc(var(--vh, 1vh) * 100);
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, [col-start] 1fr);
  grid-column-gap: 16px;
  grid-template-rows: auto;
  height: 100vh;

  &::-webkit-scrollbar {
    display: block;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 60px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    @apply bg-lightGreyColor;
    border-radius: 10px;
  }

  .item-a {
    display: none;
  }

  .item-b {
    @apply bg-bgCanvas;
    grid-column-start: 1;
    grid-column-end: 5;
    display: block;
    &::-webkit-scrollbar {
      display: block;
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      margin-top: 10px;
      margin-bottom: 60px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
      @apply bg-lightGreyColor;
      border-radius: 10px;
    }
  }
}

@screen md {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-column-gap: 24px;
    grid-template-rows: auto;
    height: 100vh;
    border-collapse: separate;
    
    &::-webkit-scrollbar-track {
      margin-bottom: 10px;
    }
    .item-a {
    @apply bg-bgBar;
      grid-column-start: 1;
      grid-column-end: 4;
      display: block;
      padding-left: 24px;
    }
  
    .item-b.has-menu {
      grid-column-start: 4;
      grid-column-end: 13;
      overflow-y: auto;
      overflow-x: hidden;
      border-left: 1px solid #EEEEEE;
      margin-left: -24px;
    }

    .item-b.no-menu {
      grid-column-start: 1;
      grid-column-end: 13;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .item-b {
      &::-webkit-scrollbar-track {
        margin-bottom: 10px;
      }
    }
  }
}