.responsive-split-modal__container {
  .responsive-split-modal__overlay {
    background: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .responsive-split-modal__accessible-modal {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10001;
    display: flex;
    justify-content: center;
    align-items: center;
    .responsive-split-modal__card {
      @apply bg-white shadow max-h-full;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      img {
        width: 100%;
        max-height: 38vh;
        object-fit: cover;
        object-position: 50% 50%;
      }
      .desktop-close-button {
        display: none;
      }
      .mobile-close-button {
        position: fixed;
        top: 0.625rem;
        right: 0.375rem;
      }
      .responsive-split-modal__children-content {
        @apply bg-white;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        margin-top: -20px;
        max-height: max-content;
        overflow: hidden;
        flex: 2;
        flex-grow: 2;
      }
    }
  }
}

@media (min-width: 768px) {
  .responsive-split-modal__container {
    .responsive-split-modal__accessible-modal {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 80vw;
      height: 65vw;
      max-width: 43.75rem;
      max-width: 100vh;
      max-height: 35.5rem;
      max-height: 74%;
      border-radius: 20px;
      overflow: hidden;

      .responsive-split-modal__card {
        border-radius: 20px;
        flex-direction: row-reverse;
        .desktop-close-button {
          display: flex;
          position: fixed;
          top: 0.625rem;
          right: 0.375rem;
          z-index: 10002;
        }
        .mobile-close-button {
          display: none;
        }
        .responsive-split-modal__children-content {
          margin-top: 0px;
          height: 100%;
        }
        img {
          width: 50%;
          max-height: 100%;
          height: 100%;
          object-position: 50% 50%;
        }
      }
    }
  }
}