.week-view {
  @apply flex flex-col h-screen w-full;
  padding-top: 76px;
  padding-bottom: 4rem;
  .app-header-logo {
    @apply md:opacity-0;
  }
  .week-view-content__scrollable-view {
    @apply flex-1 overflow-y-auto;
    .app-header__welcome-text {
      @apply p-4 flex-col md:flex md:pt-0 lg:px-6;
      h2 {
        @apply text-headlineSmall md:text-headlineMedium text-textPrimary pb-2;
        letter-spacing: 0.15px;
      }
      p {
        @apply text-textSecondary pb-2;
      }
      .active-profile-name {
        @apply text-textSecondary text-bodyMedium md:text-bodyLarge mt-2;
      }
    }
  }
}

@screen md {
  .week-view {
    @apply py-0 overflow-auto;
    .week-view-content__scrollable-view {
      overflow: visible;
      .app-header__welcome-text {
        margin-top: -76px;
      }
    }
  }
}

.carousel-weeks {
  padding-left: 0;
  .simple-carousel-container {
    padding-left: 16px;
    padding-right: 10px;
  }
}

.subtitle-week {
  @apply text-textSecondary text-fontDefault;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  padding: 8px 0 19px 16px;
}

.suggestions-title-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px 16px 16px;
}

.suggestions-title {
  @apply text-textMedium text-textPrimary;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  max-width: 300px;
  padding-top: 16px;
}

.journey-with-no-suggestions-subtitle {
  @apply text-fontDefault text-textSecondary;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  max-width: 300px;
  padding-top: 10px;
  padding-bottom: 18px;
}

.suggestion-finished-text {
  @apply text-fontDefault text-textSecondary;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  padding: 8px 30px 16px 30px;
}

.suggestion-finished-divider {
  padding-bottom: 24px;
}

.suggestions-body-container {
  margin-bottom: 32px;
}

.card-specialist {
  padding: 0 16px;
}

.card-specialist-text {
  @apply text-textSecondary;
}

.week-view-grid-container {
  display: grid;
  grid-template-columns: repeat(4, [col-start] 1fr);
  grid-column-gap: 16px;
  grid-template-rows: auto;

  .week-view-item-a {
    @apply bg-bgCanvas;
    grid-column-start: 1;
    grid-column-end: 5;
    display: block;
    padding-bottom: 55px;

    .whatsapp-container {
      @apply bg-bgButton w-full rounded-2xl py-5 mb-4 text-left text-base flex items-center;
    }
  }
  .week-view-item-b {
    display: none;
  }
  .bookings-container-item-a {
    display: block;
  }
}

.bookings-header {
  display: flex;
  align-items: center;
  padding-left: 19px;
  padding-bottom: 22px;

  .btn-material {
    width: auto;
    margin-right: 16px;
  }
}

.bookings-title {
  @apply text-fontDefault text-textPrimary;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  display: flex;
  flex: 1;
}

.new-booking-button {
  margin: 24px 16px 40px 16px;

  .btn-material {
    span {
      display: flex;
      align-items: center;
    }
  }
}

.booking-card-empty {
  @apply text-fontDefault text-textSecondary;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  padding-bottom: 8px;
}

.see-all-suggestions-container {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}

.week-content-link-to-journey {
  margin: 0 16px 16px 16px;

  .week-content-link-to-journey-icon-container {
    height: 40px;
    width: 40px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 16px 0 16px;

    .week-content-link-to-journey-icon {
      height: 40px;
      width: 40px;
      border-radius: 16px;
    }
  }
}
.booking-disable-img {
  width: 72px;
}

@screen lg {
  .subtitle-week {
    padding: 30px 0 24px 27px;
  }

  .suggestions-title-container {
    padding: 24px 0px 16px 0px;
  }

  .suggestions-title-container-is-trying {
    padding: 0 0px 16px 0px;
  }

  .suggestions-title {
    max-width: 100%;
  }

  .journey-with-no-suggestions-subtitle {
    max-width: 100%;
  }

  .suggestions-body-container {
    padding: 0;
  }

  .card-specialist {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .week-content-link-to-journey {
    margin: 0 0 16px 0;
  }

  .card-specialist-img {
    max-width: 300px;
  }

  .card-specialist-inner {
    max-width: 643px;
  }

  .week-view-grid-container {
    grid-template-columns: repeat(9, [col-start] 1fr);
    grid-column-gap: 24px;
    border-collapse: separate;
    
    .week-view-item-a {
      @apply bg-bgCanvas;
      grid-column-start: 1;
      grid-column-end: 7;
      display: block;
      padding: 0 24px;
      border-right: 1px solid #EEEEEE;
      width: 100%;
    }
  
    .week-view-item-b {
      @apply bg-bgCanvas;
      grid-column-start: 7;
      grid-column-end: 10;
      display: block;
    }

    .bookings-container-item-a {
      display: none;
    }
  }

  .suggestion-finished-text {
    padding: 8px 50px 16px 50px;
  }

  .bookings-header {
    padding-left: 0;
  }

  .new-booking-button {
    margin: 0 16px 0 0;
  }

  .booking-card-empty {
    padding-bottom: 32px;
  }
}