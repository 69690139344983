.number-icon {
  border: 2px solid #723965;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.text-faq-line {
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 16px;
}

.onboarding-container {
  padding: 24px 16px 0 16px;
}

@screen lg {
  .onboarding-container {
    padding: 24px 24px 0 24px;
  }
}
