.specialist-details-loader {
  @apply flex items-center justify-center mt-10;
}
.specialist-details {
  @apply p-4 overflow-auto h-full;
  .specialist-details-header {
    @apply flex items-center mb-4;
    .specialist-details-avatar-img {
      height: 4.375rem;
      width: 4.375rem;
      border-radius: 20px;
    }
    .specialist-details-header-text {
      @apply flex flex-col items-start justify-start w-full pl-4;
      .specialist-name{
        @apply text-titleLarge text-textPrimary font-medium mb-1;
        letter-spacing: 0.15px;
      }
      .specialist-category {
        @apply text-bodyLarge text-textSecondary font-normal;
      }
    }
  }
  .specialist-summary-html {
    @apply mb-6;
    h1 {
      @apply text-titleLarge text-textPrimary font-medium mb-2;
    }

    p:first-child {
      @apply mt-2;
    }
    ul {
      @apply pl-6;
      li {
        @apply mt-1;
        list-style: disc;
      }
    }
  }
}
.professional-details-error-container{
  @apply p-4 mt-4;
}
