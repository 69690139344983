.no-suggestion-journey__skelleton {
  @apply w-full flex flex-col items-center;
  .no-suggestion-journey__skelleton-img {
    @apply w-14 h-14 rounded-full mb-3;
  }
  .no-suggestion-journey__skelleton-title {
    @apply h-6 rounded-3xl w-3/4 max-w-4xl;
  }
  .no-suggestion-journey__skelleton-subtitle {
    @apply h-4 mt-3 mb-4 rounded-3xl w-2/4;
    max-width: 230px;
  }
  .no-suggestion-journey__skelleton-option {
    @apply w-full p-4 flex items-center;
    .no-suggestion-journey__skelleton-option-img {
      @apply h-4 w-4 rounded-full;
    }
    .no-suggestion-journey__skelleton-option-text {
      @apply w-2/3 h-4 rounded-3xl ml-4;
      max-width: 330px;
    }
    .no-suggestion-journey__skelleton-option-icon {
      @apply h-5 w-5 rounded-full ml-auto;
    }
  }
}