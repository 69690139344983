.accessible-modal-terms {
  height: 90%;
  .terms-modal {
    @apply max-w-3xl;
    .modal-footer {
      @apply flex justify-center;
      margin: 0px;
      background-color: #FFFFFF;
      position: -webkit-sticky;
      position: sticky;
      bottom: -14px;
    }
  }

  blockquote {
    margin-left: 50px;
  }

  section {
    margin-bottom: 15px;
  }
  
  button {
    @apply m-2;
    height: 38px;
    width: 250px;
  }

  h1 {
    font-weight: 600;
    padding-bottom: 10px;
  }

  ul {
    margin-left: 5px;
    li {
      margin-left: 15px;
      list-style-type: disc;
      font-weight: 500;
      padding-bottom: 10px;
    }
  }

  ol {
    margin-left: 15px;
    list-style-type: lower-roman;
    font-weight: 500;
    padding-bottom: 10px;
  }

  .sub-list-roman {
    margin-left: 40px;
  }

  .list-number {
    margin-left: 15px;
    list-style-type: decimal;
    font-weight: 500;
    padding-bottom: 10px;
  }

  .sub-list-lower-alpha {
    margin-left: 40px;
    list-style-type: lower-alpha;
  }

  .sub-intern-list-lower-alpha {
    margin-left: 80px;
    list-style-type: lower-alpha;
  }

  table, th, td {
    border: 1px solid black;
  }

  dd, dl, dt {
    font-weight: 500;
    padding-bottom: 10px;
  }

  dt {
    margin-left: 15px;
    display: list-item;
    list-style-type: decimal;
    font-weight: 700;
  }

  .roman-intern-dt {
    margin-left: 40px;
    display: list-item;
    font-weight: 700;
    list-style-type: lower-roman;
  }

  .alpha li {
    list-style-type: lower-alpha;
  }

  .cookies-list li{
    list-style-type: none !important;
    margin-left: 0 !important;
  }
   
  .cookies-link, a {
  color: #9A4178;
  text-decoration: underline;
  }
}


