.app-header {
  @apply w-full flex items-center justify-start p-4 md:p-6 md:items-start
  top-0 bg-bgCanvas md:bg-transparent fixed md:relative;
  z-index: 1;
  .goBack-btn {
    @apply flex items-center justify-center;
    max-height: 44px;
  }
  .app-header-logo {
    width: 30%;
    height: fit-content;
    max-width: 9rem;
    min-width: 6rem;
  }
  .app-header__title {
    @apply text-headlineSmall;
    margin: auto 0;
    letter-spacing: 0.15px;
  }
  .select-profile-btn {
    @apply flex items-center pt-1 ml-auto text-textPrimary;
    .active-profile-name {
      @apply pr-2;
    }
    &:disabled {
      @apply text-textDisable;
    }
  }
}

.modal-container {
  &.choose-profile-modal {
    @apply w-full md:w-96;
    padding: 0 !important;
    overflow: unset !important;
    .header-modal {
      @apply m-0 pt-7 pb-6 px-4;
      p {
        @apply text-titleLarge;
      }
    }
    .choose-profile-modal-content {
      .profiles-list-container {
        @apply px-4 pb-8 pt-3 flex-1;
        overflow: auto;
        max-height: calc(100vh - 77px - 84px);
        &::-webkit-scrollbar-track {
          margin: 0;
        }
      }
      .profiles-list {
        &.loading {
          @apply relative;
          &::before {
            content: '';
            height: 100%;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.5);
            position: absolute;
          }
        }
        .content-card-container {
          .new-chip-container {
            margin-left: 16px;
          }
        }
        .content-card-container.filled {
          background-color: #F6EBF2;
          border: 1px solid #9A4178;
          .content-card-title, .subhead {
            color: #9A4178 !important;
          }
        }
      }
    }
  }
  .choose-profile-modal-loader {
    @apply flex h-full w-full justify-center items-center pb-6;
    height: calc(100% - 84px);
    position: absolute;
    z-index: 2;
  }
}

@screen md {
  .modal-container {
    &.choose-profile-modal {
      max-height: 70vh !important;
      .choose-profile-modal-content {
        .profiles-list-container {
          max-height: calc(70vh - 84px);
          &::-webkit-scrollbar-track {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
