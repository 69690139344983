.cookies-info-container {
  position: fixed;
  z-index: 20000;
  bottom: 0;
  width: 100%;
  background: rgba(249, 249, 249, 0.9);
}

.cookies-info {
  position: relative;
  display: flex;
  flex: 1;
  padding: 20px 6%;
  align-items: flex-start;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  flex-direction: column; 

  p {
    width: 100%;
  }
}

.cookies-info-close-button {
  flex: 1;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  background: #9A4178;
  border-radius: 12px;
  color: #FFFFFF;
  padding: 6px 35px;
  margin-top: 16px;
}

.button-text-inline {
  .link-cookies-banner {
    color: #9A4178;
    text-decoration: underline;
    font-weight: 500;
  }
}

@screen lg {
  .cookies-info {
    padding: 20px 40px 20px 40px;
    flex-direction: row;
    align-items: center;

    p {
      width: 100%;
    }
  }
  .cookies-info-close-button {
    margin-top: 0px;
  }
  .button-text-inline {
    display: contents;
  }
}
