.loading-label-input {
  @apply bg-bgDisable;
  border-radius: 16px;
  width: 74px;
  height: 16px;
}

.loading-input {
  @apply bg-bgDisable;
  border: 1px solid #DADBE1;
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
  max-width: 343px;
  margin-top: 8px;
}

.loading-input-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 16px;
}