.empty-view-container {
  background: transparent;
  border-radius: 24px;
  padding: 56px 26px 68px 26px;
  margin: 0 16px 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .empty-title {
    @apply text-fontSmall text-textPrimary;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center;
  }
  
  .empty-description {
    @apply text-fontDefault text-textSecondary;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    padding-bottom: 34px;
    text-align: center;
  }
  
  .empty-last-button {
    margin-top: 10px;
  }
}
  
@screen lg {
  .empty-list-view {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .empty-list-view-inner {
    @apply max-w-xl;
    width: 100%;
  }
  
  .empty-view-container {
    margin: 0;
    background: #FFFFFF;
  }
}