
.banner-info-icon {
  margin-top: 16px;
}

.banner-with-explanation-container {
  margin: 50px 16px 24px 16px;

  .card-container {
    @apply border border-divider bg-bgCanvas rounded-2xl flex flex-col;
    box-sizing: border-box;
    filter: none;
    padding: 0 16px 16px 16px;

    p {
      margin-top: 15px;
    }
    
    .banner-title {
      @apply text-textPrimary text-fontDefault font-medium;
    }
  
    .banner-text {
      @apply text-textSecondary text-fontDefault;
    }
    
    .loading-image {
      @apply bg-bgDisable;
      width: 40px;
      height: 40px;
      border-radius: 16px;
      margin-top: 16px;
      margin-bottom: 8px;
    }
  
    .loading-full-line {
      @apply bg-bgDisable;
      height: 16px;
      border-radius: 8px;
      margin-top: 8px;
    }
  
    .loading-middle-line {
      @apply bg-bgDisable;
      height: 16px;
      max-width: 267px;
      border-radius: 8px;
      margin-top: 8px;
    }
  
    .loading-small-line {
      @apply bg-bgDisable;
      height: 16px;
      max-width: 145px;
      border-radius: 8px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}