.auth-grid .auth-item-b.container-outer {
  align-items: flex-start;
  margin-top: 0;
  display: flex;
  height: 100%;
  background-color: #FFFFFF;

  .pre-login {
    height: 100%;
    transition: align-items 3s ease;
  }

  .pre-login.fade-in {
    align-items: flex-start;

    .pre-login-inner {
      max-width: 100%;
      height: calc(100vh - 40px);
    }
  }

  .pre-login.fade-out {
    align-items: flex-start;
  }
}

.onboarding-main-content.container-inner {
  height: calc(100vh - 106px);

  .px-4 {
    height: 100%;
  }
}

@screen md {
  .auth-grid .auth-item-b .pre-login.fade-in .pre-login-inner {
    margin-top: 0;
  }

  .auth-grid .auth-item-b.container-outer {
    align-items: flex-start;
    margin-top: 0;
    display: flex;
    overflow: overlay;
    height: 100%;

    .pre-login {
      height: 100%;
      transition: align-items 3s ease;
    }

    .pre-login.fade-in {
      align-items: flex-start;
    }

    .pre-login.fade-out {
      align-items: flex-start;
    }
  }

  .container-inner {
    height: 705px;

    .px-4 {
      height: 100%;
    }
  }
}
