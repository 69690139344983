@tailwind base;
@tailwind components;
@tailwind utilities;

/*! purgecss start ignore */
*, html {
  font-family: 'Rubik', sans-serif;

  &::-webkit-scrollbar {
    display: block;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-top: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #C7C7C7;
    border-radius: 10px;
  }
}
.schedule-message__biography-first-header {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 12px;
}
.schedule-message__biography-header {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 20px;
}
.schedule-message__biography-text {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 20px;
}

.Toastify__toast-container--bottom-right {
  @apply w-full right-0 left-0 px-4 bottom-0 p-0;
}
.Toastify__toast {
  @apply mb-0;
}
.toast-message {
  @apply bg-overlayDarkest text-bgBox rounded-2xl text-bodyMedium max-w-sm
  mb-2 md:mr-2 ml-auto;
  &:last-child {
    @apply mb-16 md:mb-6;
  }
  .Toastify__toast-icon {
    @apply mr-4;
  }
  .Toastify__close-button, .Toastify__close-button--light {
    @apply text-bgBox opacity-100 self-center;
  }
}
.alert-container {
  border-radius: 8px !important;
  min-height: unset !important;
  > div {
    padding: 0;
  }
  .alert-message-container {
    @apply p-4;
  }
}

@font-face {
  font-family: 'Theicon';
  src: url('../public/fonts/Theicon.eot?4phgd4');
  src: url('../public/fonts/Theicon.eot?4phgd4#iefix') format('embedded-opentype'),
  url('../public/fonts/Theicon.ttf?4phgd4') format('truetype'),
  url('../public/fonts/Theicon.woff?4phgd4') format('woff'),
  url('../public/fonts/Theicon.svg?4phgd4#Theicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Theicon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ProfileBold::before {
  content: "\e9b6";
}
.icon-ProfileLight::before {
  content: "\e9b7";
}
.icon-2UsersBold::before {
  content: "\e9e9";
}
.icon-2UsersLight::before {
  content: "\e900";
}
.icon-3UsersBold::before {
  content: "\e901";
}
.icon-3UsersLight::before {
  content: "\e902";
}
.icon-ActivityBold::before {
  content: "\e903";
}
.icon-ActivityLight::before {
  content: "\e904";
}
.icon-AddUserBold::before {
  content: "\e905";
}
.icon-AddUserLight::before {
  content: "\e906";
}
.icon-AllergieLight::before {
  content: "\e907";
}
.icon-ArrowDown2Light::before {
  content: "\e908";
}
.icon-ArrowDownCircleLight::before {
  content: "\e909";
}
.icon-ArrowDownLight::before {
  content: "\e90a";
}
.icon-ArrowDownSquareLight::before {
  content: "\e90b";
}
.icon-ArrowLeft2Light::before {
  content: "\e90c";
}
.icon-ArrowLeftCircleLight::before {
  content: "\e90d";
}
.icon-ArrowLeftLight::before {
  content: "\e90e";
}
.icon-ArrowLeftSquareLight::before {
  content: "\e90f";
}
.icon-ArrowRight2Light::before {
  content: "\e911";
}
.icon-ArrowRightCircleLight::before {
  content: "\e912";
}
.icon-ArrowRightLight::before {
  content: "\e913";
}
.icon-ArrowRightSquareLight::before {
  content: "\e914";
}
.icon-ArrowUp2Light::before {
  content: "\e915";
}
.icon-ArrowUpCircleLight::before {
  content: "\e916";
}
.icon-ArrowUpLight::before {
  content: "\e917";
}
.icon-ArrowUpSquareLight::before {
  content: "\e918";
}
.icon-BabyHeartLight::before {
  content: "\e919";
}
.icon-BabyLight::before {
  content: "\e91a";
}
.icon-BallLight::before {
  content: "\e91b";
}
.icon-BirthdayLight::before {
  content: "\e91c";
}
.icon-BookmarkBold::before {
  content: "\e91d";
}
.icon-BookmarkLight::before {
  content: "\e91e";
}
.icon-BrainLight::before {
  content: "\e91f";
}
.icon-BuildingBold::before {
  content: "\e920";
}
.icon-BuildingLight::before {
  content: "\e921";
}
.icon-CalendarAttentionLight::before {
  content: "\e922";
}
.icon-CalendarBlockBold::before {
  content: "\e923";
}
.icon-CalendarBlockLight::before {
  content: "\e924";
}
.icon-CalendarBold::before {
  content: "\e925";
}
.icon-CalendarLight::before {
  content: "\e928";
}
.icon-CalendarCheckBold::before {
  content: "\e926";
}
.icon-CalendarCheckLight::before {
  content: "\e927";
}
.icon-CalendarWeekBold::before {
  content: "\e929";
}
.icon-CalendarWeekLight::before {
  content: "\e92a";
}
.icon-CameraBold::before {
  content: "\e92b";
}
.icon-CameraLight::before {
  content: "\e92c";
}
.icon-CategoryBold::before {
  content: "\e92d";
}
.icon-CategoryLight::before {
  content: "\e92e";
}
.icon-ChangeBold::before {
  content: "\e92f";
}
.icon-ChangeLight::before {
  content: "\e930";
}
.icon-ChatBold::before {
  content: "\e931";
}
.icon-ChatHelpBold::before {
  content: "\e932";
}
.icon-ChatHelpLight::before {
  content: "\e933";
}
.icon-ChatLight::before {
  content: "\e934";
}
.icon-ChatSquareBold::before {
  content: "\e935";
}
.icon-ChatSquareLight::before {
  content: "\e936";
}
.icon-ChatSquareMoreLight::before {
  content: "\e937";
}
.icon-CloseCircleBold::before {
  content: "\e938";
}
.icon-CloseCircleLight::before {
  content: "\e939";
}
.icon-CloseLight::before {
  content: "\e93a";
}
.icon-CloseSquareBold::before {
  content: "\e93b";
}
.icon-CloseSquareLight::before {
  content: "\e93c";
}
.icon-ConnectionLight::before {
  content: "\e93d";
}
.icon-CopyLight::before {
  content: "\e93e";
}
.icon-DangerBold::before {
  content: "\e93f";
}
.icon-DangerCircleBold::before {
  content: "\e940";
}
.icon-DangerCircleLight::before {
  content: "\e941";
}
.icon-DangerTriangleLight::before {
  content: "\e942";
}
.icon-DocumentBold::before {
  content: "\e943";
}
.icon-DocumentLight::before {
  content: "\e944";
}
.icon-Down2Bold::before {
  content: "\e945";
}
.icon-DownCircleBold::before {
  content: "\e946";
}
.icon-DownloadBold::before {
  content: "\e947";
}
.icon-DownloadLight::before {
  content: "\e948";
}
.icon-DownSquareBold::before {
  content: "\e949";
}
.icon-EditBold::before {
  content: "\e94a";
}
.icon-EditLight::before {
  content: "\e94b";
}
.icon-ExamBold::before {
  content: "\e94d";
}
.icon-ExamLight::before {
  content: "\e94e";
}
.icon-EyeDark::before {
  content: "\e94f";
}
.icon-EyeLight::before {
  content: "\e950";
}
.icon-EyeOffBold::before {
  content: "\e951";
}
.icon-EyeOffLight::before {
  content: "\e952";
}
.icon-FacebookBold::before {
  content: "\e953";
}
.icon-FacebookLight::before {
  content: "\e954";
}
.icon-FilterBold::before {
  content: "\e955";
}
.icon-FilterLight::before {
  content: "\e956";
}
.icon-FlagBold::before {
  content: "\e957";
}
.icon-FlagLight::before {
  content: "\e958";
}
.icon-FolderBold::before {
  content: "\e959";
}
.icon-FolderLight::before {
  content: "\e95a";
}
.icon-FoldersBold::before {
  content: "\e95b";
}
.icon-FoldersLight::before {
  content: "\e95c";
}
.icon-GlobeLight::before {
  content: "\e95d";
}
.icon-Google1Bold::before {
  content: "\e94c";
}
.icon-Google2Bold::before {
  content: "\e9e1";
}
.icon-Google3Bold::before {
  content: "\e9e2";
}
.icon-Google4Bold::before {
  content: "\e9f2";
}
.icon-GoogleLight::before {
  content: "\e95e";
}
.icon-GraphBold::before {
  content: "\e95f";
}
.icon-GraphLight::before {
  content: "\e960";
}
.icon-HeartBold::before {
  content: "\e961";
}
.icon-HeartLight::before {
  content: "\e962";
}
.icon-HelpBold::before {
  content: "\e963";
}
.icon-HelpLight::before {
  content: "\e964";
}
.icon-HomeBold::before {
  content: "\e965";
}
.icon-HomeLight::before {
  content: "\e966";
}
.icon-HospitalLight::before {
  content: "\e967";
}
.icon-IdBold::before {
  content: "\e968";
}
.icon-IdLight::before {
  content: "\e969";
}
.icon-InfoSquareBold::before {
  content: "\e96a";
}
.icon-InfoSquareLight::before {
  content: "\e96b";
}
.icon-InstagramBold::before {
  content: "\e96c";
}
.icon-InstagramLight::before {
  content: "\e96d";
}
.icon-InvoiceBold::before {
  content: "\e96e";
}
.icon-Journey2Bold::before {
  content: "\e96f";
}
.icon-Journey2Light::before {
  content: "\e970";
}
.icon-JourneyBold::before {
  content: "\e971";
}
.icon-JourneyLight::before {
  content: "\e972";
}
.icon-Left2Bold::before {
  content: "\e973";
}
.icon-LeftCircleBold::before {
  content: "\e974";
}
.icon-LeftSquareBold::before {
  content: "\e975";
}
.icon-LessLight::before {
  content: "\e976";
}
.icon-LinkBold::before {
  content: "\e977";
}
.icon-LinkLight::before {
  content: "\e97a";
}
.icon-LinkedinBold::before {
  content: "\e978";
}
.icon-LinkedinLight::before {
  content: "\e979";
}
.icon-LoadingLight::before {
  content: "\e97b";
}
.icon-LocationBold::before {
  content: "\e97c";
}
.icon-LocationLight::before {
  content: "\e97d";
}
.icon-LockBold::before {
  content: "\e97e";
}
.icon-LockLight::before {
  content: "\e97f";
}
.icon-LoginBold::before {
  content: "\e980";
}
.icon-LoginLight::before {
  content: "\e981";
}
.icon-LogoutBold::before {
  content: "\e982";
}
.icon-LogoutLight::before {
  content: "\e983";
}
.icon-MedicalRecordBold::before {
  content: "\e984";
}
.icon-MedicalRecordLight::before {
  content: "\e985";
}
.icon-MessageBold::before {
  content: "\e986";
}
.icon-MessageLight::before {
  content: "\e987";
}
.icon-MinusBold::before {
  content: "\e988";
}
.icon-MinusLight::before {
  content: "\e989";
}
.icon-MomentLight::before {
  content: "\e98a";
}
.icon-MoreCircleBold::before {
  content: "\e98b";
}
.icon-MoreCircleLight::before {
  content: "\e98c";
}
.icon-MoreLight::before {
  content: "\e98d";
}
.icon-MoreSquareBold::before {
  content: "\e98e";
}
.icon-MoreSquareLight::before {
  content: "\e98f";
}
.icon-MoveLight::before {
  content: "\e990";
}
.icon-NotificationBold::before {
  content: "\e991";
}
.icon-NotificationLight::before {
  content: "\e992";
}
.icon-NumberLight::before {
  content: "\e993";
}
.icon-NurseLight::before {
  content: "\e994";
}
.icon-OpenBookBold::before {
  content: "\e995";
}
.icon-OpenBookLight::before {
  content: "\e996";
}
.icon-PaperBold::before {
  content: "\e997";
}
.icon-PaperclipLight::before {
  content: "\e998";
}
.icon-PaperDownloadBold::before {
  content: "\e999";
}
.icon-PaperDownloadLight::before {
  content: "\e99a";
}
.icon-PaperFailBold::before {
  content: "\e99b";
}
.icon-PaperFailLight::before {
  content: "\e99c";
}
.icon-PaperHeartBold::before {
  content: "\e99d";
}
.icon-PaperHeartLight::before {
  content: "\e99e";
}
.icon-PaperLight::before {
  content: "\e99f";
}
.icon-PaperNegativeBold::before {
  content: "\e9a0";
}
.icon-PaperNegativeLight::before {
  content: "\e9a1";
}
.icon-PaperPlusBold::before {
  content: "\e9a2";
}
.icon-PaperPlusLight::before {
  content: "\e9a3";
}
.icon-PaperUploadBold::before {
  content: "\e9a4";
}
.icon-PaperUploadLight::before {
  content: "\e9a5";
}
.icon-PasswordBold::before {
  content: "\e9a6";
}
.icon-PasswordLight::before {
  content: "\e9a7";
}
.icon-PhoneBold::before {
  content: "\e9a8";
}
.icon-PhoneLight::before {
  content: "\e9a9";
}
.icon-PhonendoscopeBold::before {
  content: "\e9aa";
}
.icon-PhonendoscopeLight::before {
  content: "\e9ab";
}
.icon-PhotoBold::before {
  content: "\e9ac";
}
.icon-PhotoLight::before {
  content: "\e9ad";
}
.icon-PillBold::before {
  content: "\e9ae";
}
.icon-PillLight::before {
  content: "\e9af";
}
.icon-PlayBold::before {
  content: "\e9b0";
}
.icon-PlayLight::before {
  content: "\e9b1";
}
.icon-Plus2Light::before {
  content: "\e9b2";
}
.icon-PlusBold::before {
  content: "\e9b3";
}
.icon-PlusLight::before {
  content: "\e9b4";
}
.icon-PregnantLight::before {
  content: "\e9b5";
}
.icon-RefreshLight::before {
  content: "\e9b8";
}
.icon-Right2Bold::before {
  content: "\e9b9";
}
.icon-RightCircleBold::before {
  content: "\e9ba";
}
.icon-RightSquareBold::before {
  content: "\e9bb";
}
.icon-RoundLight::before {
  content: "\e9bc";
}
.icon-RullerLight::before {
  content: "\e9bd";
}
.icon-SadBold::before {
  content: "\e9be";
}
.icon-SadLight::before {
  content: "\e9bf";
}
.icon-ScanBold::before {
  content: "\e9c0";
}
.icon-ScanLight::before {
  content: "\e9c1";
}
.icon-SearchBold::before {
  content: "\e9c2";
}
.icon-SearchLight::before {
  content: "\e9c3";
}
.icon-SendBold::before {
  content: "\e9c4";
}
.icon-SendLight::before {
  content: "\e9c5";
}
.icon-SettingsBold::before {
  content: "\e9c6";
}
.icon-SettingsLight::before {
  content: "\e9c7";
}
.icon-ShoeLight::before {
  content: "\e9c8";
}
.icon-SmartphoneLight::before {
  content: "\e9c9";
}
.icon-SquareLight::before {
  content: "\e9ca";
}
.icon-StarBold::before {
  content: "\e9cb";
}
.icon-StarLight::before {
  content: "\e9cc";
}
.icon-SwapBold::before {
  content: "\e9cd";
}
.icon-SwapLight::before {
  content: "\e9ce";
}
.icon-TeethBold::before {
  content: "\e9cf";
}
.icon-TeethLight::before {
  content: "\e9d0";
}
.icon-TheiaLight::before {
  content: "\e9d1";
}
.icon-ThumbsDownBold::before {
  content: "\e9d2";
}
.icon-ThumbsDownLight::before {
  content: "\e9d3";
}
.icon-ThumbsUpBold::before {
  content: "\e9d4";
}
.icon-ThumbsUpLight::before {
  content: "\e9d5";
}
.icon-TickLight::before {
  content: "\e9d6";
}
.icon-TickRoundBold::before {
  content: "\e9d7";
}
.icon-TickRoundLight::before {
  content: "\e9d8";
}
.icon-TickSquareBold::before {
  content: "\e9d9";
}
.icon-TickSquareLight::before {
  content: "\e9da";
}
.icon-TimeCircleBold::before {
  content: "\e9db";
}
.icon-TimeCircleLight::before {
  content: "\e9dc";
}
.icon-TimeSquareBold::before {
  content: "\e9dd";
}
.icon-TimeSquareLight::before {
  content: "\e9de";
}
.icon-TrashBold::before {
  content: "\e9df";
}
.icon-TrashLight::before {
  content: "\e9e0";
}
.icon-UndoLight::before {
  content: "\e9e3";
}
.icon-Up2Bold::before {
  content: "\e9e4";
}
.icon-UpCircleBold::before {
  content: "\e9e5";
}
.icon-UploadBold::before {
  content: "\e9e6";
}
.icon-UploadLight::before {
  content: "\e9e7";
}
.icon-UpSquareBold::before {
  content: "\e9e8";
}
.icon-UsgLight::before {
  content: "\e9ea";
}
.icon-VideoBold::before {
  content: "\e9eb";
}
.icon-VideoLight::before {
  content: "\e9ec";
}
.icon-Voice2Bold::before {
  content: "\e9ed";
}
.icon-Voice2Light::before {
  content: "\e9ee";
}
.icon-VoiceBold::before {
  content: "\e9ef";
}
.icon-VoiceLight::before {
  content: "\e9f0";
}
.icon-WalletBold::before {
  content: "\e9f1";
}
.icon-WalletLight::before {
  content: "\e910";
}
.icon-WaterDropBold::before {
  content: "\e9f3";
}
.icon-WaterDropLight::before {
  content: "\e9f4";
}
.icon-WeekBold::before {
  content: "\e9f5";
}
.icon-WeekLight::before {
  content: "\e9f6";
}
.icon-WeightLight::before {
  content: "\e9f7";
}
.icon-WhatsAppBold::before {
  content: "\e9f8";
}
.icon-WhatsAppLight::before {
  content: "\e9f9";
}

// react-dates
.DateInput {
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  padding: 6px;
  width: 100% !important;
  background: transparent !important;
}
.SingleDatePicker {
  width: 100% !important;
}
.SingleDatePickerInput {
  width: 100% !important;
  background-color: transparent !important;
}
.SingleDatePickerInput__withBorder {
  border-color: transparent !important;
}

#card-element {
  outline: none;
}

.StripeElement {
  @apply text-textPrimary text-fontDefault;
  display: block;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
  margin-top: 8px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  outline: none;
  padding: 12px 16px;
}

.StripeElement--invalid {
  border: 1px solid #FF7951;
}

.StripeElement--empty {
  border: 1px solid #EEEEEE;
}

.StripeElement--empty:hover {
  border: 1px solid rgba(0, 0, 0, 0.6);
}

.StripeElement:hover {
  border: 1px solid rgba(0, 0, 0, 0.87);
}

.StripeElement--focus {
  border: 1px solid #9A4178;
}
#card-errors {
  @apply text-fontExtraSmall text-textSecondary;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: flex;
  margin-top: 8px;
}

.input-wrapper {
  .react-datepicker-wrapper {
    display: flex;
    flex: 1;
  }
}