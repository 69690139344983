.input-search {
  position: relative;
  label {
    @apply text-textPrimary text-fontDefault;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
  }
  .select-input-search-options {
    background: #FFFFFF;
    height: auto;
    position: fixed;
    z-index: 99;
    width: 100%;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    left: 0;
    li {
      cursor: pointer;
      .clean-input {
        position: absolute;
        right: 16px;
        top: 8px;
      }
      &.select__option {
        @apply text-fontDefault text-textPrimary;
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: normal;
        padding: 12px 16px;
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
      &.li__input-option {
        margin-top: 16px;
        border-bottom: 1px solid #EEEEEE;
      }
      .close-options {
        margin-left: 10px;
      }
    }
    .divider-search-list {
      margin-left: 50px;
    }
  }
  input.input {
    @apply text-textPrimary text-fontDefault;
    display: block;
    width: 100%;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    padding: 12px 16px;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    outline: none;
  } 
}


.select__options-input-search {
  @apply w-full items-center;
  background-image: url('data:image/svg+xml, <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.869 9.63616C4.811 9.57965 4.563 9.36631 4.359 9.16757C3.076 8.00245 0.976 4.96299 0.335 3.37215C0.232 3.13055 0.014 2.51974 0 2.19339C0 1.88068 0.072 1.58258 0.218 1.29811C0.422 0.94351 0.743 0.659049 1.122 0.503179C1.385 0.402838 2.172 0.246969 2.186 0.246969C3.047 0.0910993 4.446 0.00537109 5.992 0.00537109C7.465 0.00537109 8.807 0.0910993 9.681 0.218717C9.695 0.23333 10.673 0.3892 11.008 0.559682C11.62 0.872395 12 1.48321 12 2.13689V2.19339C11.985 2.61911 11.605 3.51438 11.591 3.51438C10.949 5.0195 8.952 7.98881 7.625 9.18219C7.625 9.18219 7.284 9.51828 7.071 9.66441C6.765 9.89237 6.386 10.0054 6.007 10.0054C5.584 10.0054 5.19 9.87775 4.869 9.63616Z" fill="black" fill-opacity="0.6"/></svg>');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #FFFFFF;
  background-size: 12px;
  background-position: right 16px top 50%;
  border: 1px solid rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  border-radius: 4px;
  -webkit-appearance: none;
  padding: 12px 16px;
  height: 50px;
  outline: none;
  margin-top: 8px;
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.87);
    background-image: url('data:image/svg+xml, <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.869 9.63616C4.811 9.57965 4.563 9.36631 4.359 9.16757C3.076 8.00245 0.976 4.96299 0.335 3.37215C0.232 3.13055 0.014 2.51974 0 2.19339C0 1.88068 0.072 1.58258 0.218 1.29811C0.422 0.94351 0.743 0.659049 1.122 0.503179C1.385 0.402838 2.172 0.246969 2.186 0.246969C3.047 0.0910993 4.446 0.00537109 5.992 0.00537109C7.465 0.00537109 8.807 0.0910993 9.681 0.218717C9.695 0.23333 10.673 0.3892 11.008 0.559682C11.62 0.872395 12 1.48321 12 2.13689V2.19339C11.985 2.61911 11.605 3.51438 11.591 3.51438C10.949 5.0195 8.952 7.98881 7.625 9.18219C7.625 9.18219 7.284 9.51828 7.071 9.66441C6.765 9.89237 6.386 10.0054 6.007 10.0054C5.584 10.0054 5.19 9.87775 4.869 9.63616Z" fill="black" fill-opacity="0.87"/></svg>');
  }
  &:focus {
    border: 1px solid #9A4178;
    background-image: url('../../../assets/purple-arrow-down.png');
  }
  &:focus-visible {
    border: 1px solid #9A4178;
    background-image: url('../../../assets/purple-arrow-down.png');
  }
  &:disabled {
    @apply bg-white text-textDisable;
    border: 1px solid rgba(0, 0, 0, 0.42);
    background-image: url('data:image/svg+xml, <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.869 9.63616C4.811 9.57965 4.563 9.36631 4.359 9.16757C3.076 8.00245 0.976 4.96299 0.335 3.37215C0.232 3.13055 0.014 2.51974 0 2.19339C0 1.88068 0.072 1.58258 0.218 1.29811C0.422 0.94351 0.743 0.659049 1.122 0.503179C1.385 0.402838 2.172 0.246969 2.186 0.246969C3.047 0.0910993 4.446 0.00537109 5.992 0.00537109C7.465 0.00537109 8.807 0.0910993 9.681 0.218717C9.695 0.23333 10.673 0.3892 11.008 0.559682C11.62 0.872395 12 1.48321 12 2.13689V2.19339C11.985 2.61911 11.605 3.51438 11.591 3.51438C10.949 5.0195 8.952 7.98881 7.625 9.18219C7.625 9.18219 7.284 9.51828 7.071 9.66441C6.765 9.89237 6.386 10.0054 6.007 10.0054C5.584 10.0054 5.19 9.87775 4.869 9.63616Z" fill="black" fill-opacity="0.42"/></svg>');
  }
  &.error {
    border: 1px solid #FF7951;
  }
  .select__option__placeholder {
    @apply text-textSecondary text-fontDefault;
    font-family: 'Rubik', sans-serif;
    font-style: italic !important;
    font-weight: normal;
  }
}

.select__options-input-search:invalid {
  @apply text-textSecondary text-fontDefault;
  font-family: 'Rubik', sans-serif;
  font-style: italic;
  font-weight: normal;
  border: 1px solid #EEEEEE;
  &:hover:enabled {
    border: 1px solid rgba(0, 0, 0, 0.6);
    background-image: url('data:image/svg+xml, <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.869 9.63616C4.811 9.57965 4.563 9.36631 4.359 9.16757C3.076 8.00245 0.976 4.96299 0.335 3.37215C0.232 3.13055 0.014 2.51974 0 2.19339C0 1.88068 0.072 1.58258 0.218 1.29811C0.422 0.94351 0.743 0.659049 1.122 0.503179C1.385 0.402838 2.172 0.246969 2.186 0.246969C3.047 0.0910993 4.446 0.00537109 5.992 0.00537109C7.465 0.00537109 8.807 0.0910993 9.681 0.218717C9.695 0.23333 10.673 0.3892 11.008 0.559682C11.62 0.872395 12 1.48321 12 2.13689V2.19339C11.985 2.61911 11.605 3.51438 11.591 3.51438C10.949 5.0195 8.952 7.98881 7.625 9.18219C7.625 9.18219 7.284 9.51828 7.071 9.66441C6.765 9.89237 6.386 10.0054 6.007 10.0054C5.584 10.0054 5.19 9.87775 4.869 9.63616Z" fill="black" fill-opacity="0.6"/></svg>');
  }
  &:focus:enabled {
    border: 1px solid #9A4178;
    background-image: url('../../../assets/purple-arrow-down.png');
  }
  &:focus-visible {
    border: 1px solid #9A4178;
    background-image: url('../../../assets/purple-arrow-down.png');
  }
  &:disabled {
    @apply bg-white text-textDisable;
    border: 1px solid #EEEEEE;
    background-image: url('data:image/svg+xml, <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.869 9.63616C4.811 9.57965 4.563 9.36631 4.359 9.16757C3.076 8.00245 0.976 4.96299 0.335 3.37215C0.232 3.13055 0.014 2.51974 0 2.19339C0 1.88068 0.072 1.58258 0.218 1.29811C0.422 0.94351 0.743 0.659049 1.122 0.503179C1.385 0.402838 2.172 0.246969 2.186 0.246969C3.047 0.0910993 4.446 0.00537109 5.992 0.00537109C7.465 0.00537109 8.807 0.0910993 9.681 0.218717C9.695 0.23333 10.673 0.3892 11.008 0.559682C11.62 0.872395 12 1.48321 12 2.13689V2.19339C11.985 2.61911 11.605 3.51438 11.591 3.51438C10.949 5.0195 8.952 7.98881 7.625 9.18219C7.625 9.18219 7.284 9.51828 7.071 9.66441C6.765 9.89237 6.386 10.0054 6.007 10.0054C5.584 10.0054 5.19 9.87775 4.869 9.63616Z" fill="black" fill-opacity="0.42"/></svg>');
  }
  &.error {
    border: 1px solid #FF7951;
  }
}

@screen md {
  .input-search {
    .select-input-search-options {
      height: auto;
      position: absolute;
      z-index: 99;
      top: 30px;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      bottom: initial;
      overflow-y: auto;
      padding: 16px;
      li {
        &.select__option {
          padding: 12px 0;
        }
        &.li__input-option {
          margin-top: 0;
          border-bottom: none;
        }
        .clean-input {
          top: 14px;
        }
        .close-options {
          margin-left: -5px;
          margin-right: 10px;
        }
      }
      .divider-search-list {
        margin-left: 34px;
        width: 90% !important;
      }
    }
    input.input {
      border: 1px solid rgba(0, 0, 0, 0.6);

      &:hover:enabled {
        border: 1px solid rgba(0, 0, 0, 0.87);
      }
      &:focus:enabled {
        border: 1px solid #9A4178;
      }
      &:focus-visible {
        border: 1px solid #9A4178;
      }
      &:disabled {
        @apply bg-white text-textDisable;
        border: 1px solid rgba(0, 0, 0, 0.42);
      }
      &.error {
        border: 1px solid #FF7951;
      }
      &:-webkit-autofill {
        background: #FFFFFF;
      }
    }
    input.input[value=""] {
      border: 1px solid #EEEEEE;
      &:hover:enabled {
        border: 1px solid rgba(0, 0, 0, 0.6);
      }
      &:focus:enabled {
        border: 1px solid #9A4178;
      }
      &:focus-visible {
        border: 1px solid #9A4178;
      }
      &:disabled {
        @apply bg-white text-textDisable;
        border: 1px solid #EEEEEE;
      }
      &.error {
        border: 1px solid #FF7951;
      }
    }
  }
}
