.add-exams-desktop-btn-wrapper {
  @apply hidden md:fixed md:flex justify-center;
  top: 1rem;
  right: 1rem;
  z-index: 2;
  .upload-btn-desktop {
    @apply relative;
    button {
      max-height: 44px;
    }
    .add-exam-tooltip-message-desktop {
      @apply absolute;
      z-index: 10000;
      top: 50px;
      width: 230px;
      right: 5px;
    }
  }
}

.add-exams-mobile-btn-wrapper {
  @apply fixed flex justify-center right-8 md:hidden;
  bottom: 75px;
  z-index: 1;
  .upload-btn-mobile {
    @apply relative;
    .add-exam-tooltip-message {
      @apply absolute;
      bottom: 65px;
      right: 0;
      width: 210px;
    }
  }
}

.upload-error {
  @apply fixed bottom-16 md:mx-auto mx-4 md:right-auto right-2;
  z-index: 2;
}