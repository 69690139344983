.new-checkbox-container {
  display: flex;
  position: relative;
  flex: 1;
  padding-bottom: 24px;

  .new-checkbox-divider {
    margin-top: 9px;
  }

  input[type='checkbox'] + label::before {
    content: '';
    position: relative;
    display: flex;
    margin-right: 12px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    align-self: center;
    border: 1.5px solid rgba(0, 0, 0, 0.6);
    float: left;
  }
  input[type='checkbox'] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
  }
  input[type='checkbox']:disabled + label {
    color: #575757;
  }
  input[type='checkbox']:disabled + label::before {
    background: #DDDDDD;
  }
  input[type='checkbox']:hover:enabled + label::before {
    border: 1.5px solid rgba(0, 0, 0, 0.87);
  }
}

.new-checkbox-status--success {
  input[type='checkbox']:checked + label::before {
    border: 1.5px solid #1C8020;
    background: #1C8020 url("../../../assets/check-branco-2.png") no-repeat 50% 50%;
  }
  input[type='checkbox']:checked:focus-visible + label::before {
    background: #1C8020 url("../../../assets/check-branco-2.png") no-repeat 50% 50%;
    border: 1.5px solid rgba(0, 0, 0, 0.87);
  }
  input[type='checkbox']:focus-visible + label::before {
    background: rgba(28, 128, 32, 0.1);
    border: 1.5px solid #1C8020;
  }
  input[type='checkbox']:checked:hover:enabled + label::before {
    background: #1C8020 url("../../../assets/check-branco-2.png") no-repeat 50% 50%;
    border: 1.5px solid #1C8020;
  }
}

.new-checkbox-status--selected {
  input[type='checkbox']:checked + label::before {
    border: 1.5px solid #9A4178;
    background: #9A4178 url("../../../assets/check-branco-2.png") no-repeat 50% 50%;
  }
  input[type='checkbox']:checked:focus-visible + label::before {
    background: #9A4178 url("../../../assets/check-branco-2.png") no-repeat 50% 50%;
    border: 1.5px solid rgba(0, 0, 0, 0.87);
  }
  input[type='checkbox']:focus-visible + label::before {
    background: rgba(88, 44, 199, 0.1);
    border: 1.5px solid #9A4178;
  }
  input[type='checkbox']:checked:hover:enabled + label::before {
    background: #B14582 url("../../../assets/check-branco-2.png") no-repeat 50% 50%;
    border: 1.5px solid #9A4178;
  }
}

.new-checkbox-loading-container {
  display: flex;
  margin: 21px 0;
  align-items: center;

  .loading-checkbox {
    @apply bg-bgDisable;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-right: 16px;
  }
  .loading-label {
    @apply bg-bgDisable;
    height: 16px;
    max-width: 168px;
    border-radius: 16px;
    display: flex;
    flex: 1;
  }
}