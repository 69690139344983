canvas {
  display: none;
}

.crop-container {
  display: flex;
  justify-content: center;
  .ReactCrop__crop-selection {
    box-shadow: 0 0 0 9999em rgb(205 205 205 / 47%);
    border: 0px;
  }
}

.ReactCrop__child-wrapper > img {
  max-height: 400px;
}

.ReactCrop {
  max-height: 400px;
}

.modal-crop-image {
  width: 100%;
}

.modal__inner {
  .btn-material.button-photo {
    width: 112px;
  }
}

.options-modal-inner {
  .btn-material.button-photo-del {
    color: #BB3949;
  }
  .btn-material.button-photo-del.text.disabled {
    color: rgba(0, 0, 0, 0.42);
  }
}

@screen md {
  .modal-crop-image {
    max-width: 448px;
    width: 448px;
    .modal-crop-title {
      bottom: 28px;
      width: fit-content;
      margin-left: 130px;
    }
  }  
}
