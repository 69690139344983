.auth-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  .auth-item-a {
    display: none;
  }

  .auth-item-b {
    grid-template-rows: 11%  auto;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: span 2;
    &::-webkit-scrollbar {
      display: block;
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      margin-top: 10px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      border-radius: 10px;
    }
    .screen-title-container {
      padding: 40px 34px 0px 40px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: #C7C7C7;
      border-radius: 10px;
    }
    .pre-login {
      align-items: center;
      display: flex;
      flex: 1;
      .pre-login-inner {
        @apply mx-auto;
        max-width: 420px;
        margin-top: 40px;
        &.height-banner {
          margin-bottom: 130px;
        }
        .aldeia-mobile-icon {
          width: 100%;
          svg {
            width: 117px;
            margin: 0 auto;
          }
        }
      }
      .pre-login-icon {
        width: 40%;
        svg {
          float: right;
          width: 44px;
          height: 44px;
        }
      }
    }
  }
}
.onboarding-main-content {
  background: #FFFFFF;
  border-radius: 20px 20px 0 0;
  margin-top: 16px;
  height: 100vh;
}
@screen md {
  .onboarding-main-content {
    padding: 30px 0 24px 0;
    border-radius: 20px;
    height: auto;
    min-height: 447px;
    margin-top: 0;
  }
  .auth-grid {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-column-gap: 0;
    grid-template-rows: auto;
    height: 100vh;
    
    .auth-item-a {
      display: none;
    }
    

    .auth-item-b {
      width: 100%;
      grid-column-start: 1;
      grid-column-end: 13;
      grid-row-start: 1;
      grid-row-end: 1;
      display: flex;
      flex: 1;
      align-items: center;
      border-radius: 0;
      .header-text {
        width: 50%;
        margin: 0;
      }

      .screen-title-container {
        padding: 0;
      }

      .pre-login {
        .pre-login-inner {
          @apply mx-auto;
          margin-top: 100px;
        }
      }
    }
  }
}

@screen lg {
  .auth-grid {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-column-gap: 0;
    height: 100vh;
    grid-template-rows: auto;

    .auth-item-a {
      @apply bg-bgBar;
      grid-column-start: 1;
      grid-column-end: 5;
      display: flex;
      grid-row-start: 1;
      grid-row-end: 1;

      .icon-aldeia-container {
        @apply flex flex-1 items-center justify-center pb-20;

        .icon-aldeia-desk {
          @apply flex items-center justify-center w-full;
        }
      }
    }

    .auth-item-b {
      grid-column-start: 5;
      grid-column-end: 13;
      grid-row-start: 1;
      grid-row-end: 1;
      display: flex;
      align-items: center;
      border-radius: 0;
      overflow-y: auto;
      .pre-login {
        background-size: initial;
        margin-top: 0;
        .pre-login-inner {
          @apply mx-auto;
        }
      }
    }
  }
}
