.health-plan-negative {
  .animation-up {
    -webkit-animation: animation-up-not-accepted 1.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: animation-up-not-accepted 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    position: absolute;
    top: 39%;

    svg {
      width: 118px;
      height: 118px;
      margin-top: 34px;
    }
  }

  .not-accepted-icon {
    position: absolute;
    top: 39%;
    animation: animation-not-accepted 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    svg {
      width: 118px;
      height: 118px;
    }
  }

  .list-item {
    li {
      padding: 10px 0px;
    }
  }

  .show-content {
    animation: animation-content-not-accepted 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    max-width: 600px;
    position: absolute;
    top: 39%;
    margin-top: 156px;
  }

  .button-faq-description {
    margin-left: -16px;
  }

  .button-healthplan-feedback {
    width: 100%;
  }

  @screen md {
    .accordion-wrapper {
      max-width: 600px;
      min-width: 600px;
    }

    .button-healthplan-feedback {
      width: 343px;
    }
  }

  @keyframes animation-content-not-accepted {
    0% {
      opacity: 0;
      top: 39%;
    }
    10% {
      opacity: 0.5;
      top: 28%;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }

  @keyframes animation-up-not-accepted {
    0% {
      top: 39%;
      transform: scale(1);
    }
    100% {
      top: 0;
      transform: scale(0.7);
    }
  }

  @keyframes animation-not-accepted {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}

.accessible-modal {
  .health-plan-negative {
    .accordion-wrapper {
      max-width: 100%;
      min-width: 100%;
    }

    .animation-up {
      -webkit-animation: animation-up-not-accepted-modal 1.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: animation-up-not-accepted-modal 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      position: inherit;

      svg {
        width: 118px;
        height: 118px;
      }
    }

    .not-accepted-icon {
      position: inherit;
      animation: animation-not-accepted-modal 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      margin: 30%;

      svg {
        width: 118px;
        height: 118px;
      }
    }

    .show-content {
      animation: animation-content-not-accepted-modal 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      max-width: 600px;
      position: inherit;
      margin-top: 0px;
    }

    @keyframes animation-up-not-accepted-modal {
      0% {
        margin-top: inherit;
        transform: scale(1);
      }
      100% {
        margin-top: -60px;
        transform: scale(0.65);
      }
    }

    @keyframes animation-content-not-accepted-modal {
      0% {
        opacity: 0;
      }
      10% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes animation-not-accepted-modal {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}
