.bookings-month-divider {
  @apply text-textMedium text-textPrimary;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  padding-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
  text-transform: capitalize;
}
.booking-by-month-container {
  margin-bottom: 30px;
}

@screen lg {
  .booking-list-view {
    @apply w-full pb-6 ;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .booking-list-view-inner {
    @apply max-w-xl;
    width: 100%;
  }
}