.reason-selection__button {
  @apply rounded-full text-center border-primaryDark text-primaryDark border justify-center p-1 flex-1 m-1;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
}

.reason-selection__option-button {
  @apply text-center border-primaryDark text-primaryDark border justify-center pl-4 pr-4 flex-1 m-1;
  font-size: 12px;
  border-radius: 10px;
  line-height: 14px;
  height: 40px;
}

.reason-selection__option-button--active {
  @apply bg-primaryDark text-white;
}

.reason-selection__talk-about {
  @apply text-info text-left mt-2;
  font-size: 12px;
}

.reason-selection__textarea {
  @apply p-2;
  background: '#F9F9FC';
  height: 90px;
  border: 1px solid #E1E1F4;
  border-radius: 10px;
  resize: none;
}
