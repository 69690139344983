.choose-payment-container {
  .discount-container {
    @apply pb-4;
    .discount-card {
      @apply flex items-center mt-4 border border-divider bg-bgCanvas rounded-2xl px-4 py-5;
      .discount-summary {
        @apply pl-4;
        .coupon-values {
          @apply text-success;
        }
      }
    }
  }
  .payment-methods-error-container {
    @apply mt-4;
  }
}