.accessible-error-modal {
  .modal-container {
    &.centered {
      left: 0 !important;
      top: unset !important;
      bottom: 0;
      transform: none !important;
    }
  }
}

@screen md {
  .accessible-error-modal {
    .modal-container {
      &.centered {
        left: 50% !important;
        bottom: 50%;
        transform: translate(-50%, 50%) !important;
      }
    }
  }
}
