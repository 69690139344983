.new-message-separator {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.new-message-separator::before,
.new-message-separator::after {
  content: '';
  border-top: 1px solid;
  margin: 0 10px 0 20px;
  flex: 1 0 20px;
}

.new-message-separator::after {
  margin: 0 20px 0 10px;
}

.message__body {
  @apply py-3 px-4 ml-8;
  border-radius: 0px 20px 20px 20px;
  background: rgba(255, 255, 255, 0.9);

  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

