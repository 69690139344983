.copy-input-container {
  position: relative;

  input {
    padding-right: 79px;
  }

  .copy-input-button {
    position: absolute;
    top: 25%;
    right: 6px;

    .label-btn-material {
      margin-left: 0;
    }
  }
}