.quarter-details-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .quarter-details-header {
    @apply text-textPrimary text-xl font-normal;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    background-color: transparent;
  }

  .quarter-details-content {
    display: flex;
    height: 100%;
    max-height: 92vh;
    width: 100%;
    padding: 0px 12px;
    justify-content: space-between;
    overflow: hidden;

    .card-list-view {
      overflow: auto;
      display: grid;
      gap: 16px;
      padding: 16px 12px;
      min-width: fit-content;
      justify-content: flex-start;
      align-content: flex-start;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .quarter-details-info {
      @apply text-textDisable text-base;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      justify-items: center;
      align-content: center;
      margin: 16px 12px;
      padding: 12px;
      flex: 1;
      flex-grow: 1;
      border-radius: 16px;
      border: 1px solid #EEEEEE;
      text-align: center;

      > p {
        max-width: 340px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .quarter-details-view {
    .quarter-details-content {
      padding: 0;
      .card-list-view {
        width: 100%;
        justify-content: center;
        padding-bottom: 40px;
      }
      .quarter-details-info {
        display: none;
      }
    }
  }
}
