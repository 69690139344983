.health-view {
  @apply w-full pb-6;
  #health-data-modal {
    height: 100%;
    overflow: hidden;
    .contentDrawer {
      height: calc(100% - 65px);
    }
    &::-webkit-scrollbar {
      width: 12px;
      margin: 5px 0;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #C7C7C7;
      border-radius: 10px;
    }
    .health-view-drawer-form {
      position: relative;
      height: 100%;
      .health-view-drawer-inner-form {
        @apply h-full p-4;
        max-height: calc(100% - 81px);
        overflow-y: auto;
        overflow-x: hidden;
        .health-view-individual-container {
          padding-bottom: 16px;
          .extra-inputs-container {
            display: flex;
            flex-direction: column;
            gap: 12px;
          }
        }
        .shared-container {
          display: flex;
          flex-direction: column;
          margin-top: 16px;
      
          .second-container {
            margin-top: 16px;
          }
        }
      }
      .label-medium-radio-group-list {
        margin-bottom: 16px;
      }
      .health-view-drawer-footer {
        @apply bg-bgBox;
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.09);
        .health-view-drawer-button {
          display: flex;
          flex: 1;
          flex-direction: row;
          padding: 16px;
          gap: 16px;
        }
      }
    }
  }
  .health-view-grid-container {
    @apply pt-20 md:pt-5;
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    grid-column-gap: 16px;
    grid-template-rows: auto;
    margin-bottom: 60px;
    .health-view-item-a {
      @apply bg-bgCanvas;
      grid-column-start: 1;
      grid-column-end: 5;
      display: block;
    }
    .health-view-item-b {
      display: none;
    }
    .list-item-body-container {
      .body-container {
        .title-container {
          .list-item-title {
            display: block; /* Fallback for non-webkit */
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .health-card {
      margin: 16px 16px 0;
      .health-icon {
        margin: 0 16px;
      }
      .new-prescriptions-badge {
        left: 40px;
        top: 10px;
        text-align: center;
        line-height: 20px;
        color: #FFFFFF;
        font-size: 12px;
        .number-inside-badge-container { 
          right: 3px;
          top: 0px
        }
      }
      .exams-badge {
        left: 48px;
        top: 12px;
      }
    }
  } 
}

@screen md {
  .health-view-drawer-form {
    .health-view-drawer-footer {
      width: 30%;
      min-width: 310px;
    }
  }
}

@screen lg {
  .health-view {
    #health-data-modal {
      .health-view-drawer-form {
        .health-view-drawer-inner-form {
          .shared-container {
            display: flex;
            flex-direction: row;
            margin-top: 16px;
            .first-container {
              display: flex;
              flex: 1;
              flex-direction: column;
              margin-right: 8px;
            }
            .second-container {
              display: flex;
              flex: 1;
              flex-direction: column;
              margin-top: 0;
              margin-left: 8px;
            }
          }
        }
      }
    }
    .health-view-grid-container {
      display: grid;
      grid-template-columns: repeat(12, [col-start] 1fr);
      grid-column-gap: 24px;
      grid-template-rows: auto;
      border-collapse: separate;
      padding-top: 24px;
      .health-view-item-a {
        @apply bg-bgCanvas;
        grid-column-start: 1;
        grid-column-end: 8;
        display: block;
        padding: 0 0 0 24px;
      }
      .health-view-item-b {
        @apply bg-bgCanvas;
        grid-column-start: 8;
        grid-column-end: 13;
        display: block;
        padding: 0 16px 0 0;
      }
      .health-card {
        margin: 16px 0 0 0;
        &.first-card {
          margin: 0;
        }
      }
    }
  }
}
