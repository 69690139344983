.form-input-list-container {
  .new-input-list-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }

  .label-text {
    @apply text-textPrimary text-fontDefault;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
  }

  .input-list-buttons-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    margin-top: 8px;
  }
}