.schedule-payment-error {
  @apply mb-2 flex flex-col;

  .schedule-payment-error-title {
    @apply text-textPrimary font-medium text-titleMedium pt-4 text-center;
  }

  .schedule-payment-error-subtitle {
    @apply text-bodyMedium text-textSecondary font-normal p-2 text-center mb-2;
  }
}