.payment-summary-desktop-container {
  display: none;
}

.payment-summary-container {
  @apply flex flex-col text-textPrimary font-normal text-fontDefault;
  .summary-info-container {
    @apply flex flex-1;
  }
  .summary-description {
    @apply flex-1 text-left;
  }
  .summary-price {
    @apply pl-3 text-right;
  }
}

@screen md {
  .payment-summary-desktop-container {
    @apply col-start-8 col-end-12 block;
    .payment-summary-desktop-body {
      @apply h-full bg-bgBox flex-1;
      .payment-summary-desktop-content {
        @apply pt-12 sticky top-24;
        padding-right: 18%;
        padding-left: 18%;
        
      }
    }
  }
}