.new-schedule-success-container {
  @apply flex flex-col flex-1 h-full md:-mt-8;
  .header-image {
    @apply self-center w-20 h-20;
  }
  .main-title {
    @apply text-textMedium text-textPrimary text-center m-6 mt-4 md:mx-0;
  }
  .professional-infos {
    @apply rounded-2xl m-4;
  }
  .payment-instructions-container {
    @apply flex flex-col flex-1 -mx-4 md:mx-0;
    .steps-container {
      @apply hidden md:flex bg-bgBox rounded-t-2xl w-full flex-col;
      .instruction-step {
        @apply flex items-center;
        span {
          @apply mx-4 text-textSecondary text-fontExtraSmall font-medium
          w-6 h-6 rounded-full flex items-center justify-center border-2 
          border-textSecondary;
        }
        p {
          @apply py-4 flex flex-1 text-textSecondary;
          border-bottom: 1px solid #dcdcdc; 
        }
        
      }
    }
    .instruction-title {
      @apply text-fontDefault text-textPrimary font-medium pt-0 px-4 pb-3 md:pt-3;
    }
    .copy-code-container {
      @apply flex flex-col w-full mb-4 flex-1 md:flex-none;
      .copy-code-box {
        @apply bg-bgBox md:rounded-b-2xl flex flex-col w-full py-4 px-5 flex-1;
      }
    }
    .buttons-container {
      @apply flex flex-col flex-1 w-full gap-4 px-4 md:px-0;
    }
  }
  .final-button-container {
    @apply mt-4 pb-6 flex flex-1 items-end md:flex-none;
  }
}