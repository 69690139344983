.legacy-account-validation--modal {
  .modal-container.centered {
    @apply p-0 w-full h-full md:h-fit;
  }
  &.success {
    .modal-container.centered {
      @apply h-fit;
    }
  }
  .legacy-account-validation--form {
    @apply h-full mb-auto;
    .account-validation--header {
      @apply w-full p-2 pl-0;
    }
    .account-validation--content {
      @apply px-6 md:px-8 pb-6 md:pb-8;
      .account-validation--title {
        @apply text-textPrimary text-center text-textMedium;
      }
      .account-validation--text {
        @apply pt-2 pb-8 text-center text-textSecondary text-titleMedium;
        .user-data {
          @apply text-textPrimary underline;
          text-wrap: nowrap;
        }
      }
      .account-validation--footer {
        @apply flex flex-col gap-4 pt-8;
        .account-validation--text-footer {
          @apply text-titleMedium font-medium text-textPrimary text-center;
        }
      }
    }
  }
  .legacy-account-validation--success-content {
      @apply p-6 flex flex-col h-fit;
    .header-image {
      @apply self-center;
    }
    .success-title {
      @apply text-titleLarge text-textPrimary mt-8 mb-2 text-center;
      letter-spacing: 0.15px;
    }
    .success-content {
      @apply text-bodyLarge text-textSecondary mb-8 text-center;
    }
  }
}

@screen md {
  .legacy-account-validation--modal {
    .modal-container.centered {
      max-width: 30rem;
    }
  }
}